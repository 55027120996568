import Button from 'components/Button/Button';
import { buttonStyling } from 'constants/enum';
import React from 'react';
import style from './sendEmailConfirmationForm.module.scss';
import propTypes from 'prop-types';
import { useDispatch } from 'react-redux';

function SendEmailConfirmationForm({
  closeModal,
  modalText,
  dispatchFunction
}) {
  const dispatch = useDispatch();
  const onConfirm = () => {
    closeModal();
    dispatch(dispatchFunction());
  };
  return (
    <div className={style.sendEmail}>
      <p>{modalText}</p>
      <Button
        onClick={onConfirm}
        text='Send'
        type='primary'
        className={`${buttonStyling.Open} ${buttonStyling.hover}`}
      />
    </div>
  );
}

SendEmailConfirmationForm.propTypes = {
  closeModal: propTypes.func,
  modalText: propTypes.string,
  dispatchFunction: propTypes.func
};

export default SendEmailConfirmationForm;
