import { connect } from 'react-redux';
import MyAppointmentsView from './MyAppointmentsView';
import {
  getAppointmentsViewData,
  addRequestedAppointments
} from 'state/actions/actions';
import withLocalLoader from 'components/HOCs/withLocalLoader';

function mapStateToProps({ appointments, user }) {
  return {
    carriers: appointments.carriers,
    appointedCarriers: appointments.appointedCarriers,
    zohoUserData: user.zohoData
  };
}

export default connect(mapStateToProps, {
  getAppointmentsViewData,
  addRequestedAppointments
})(withLocalLoader(MyAppointmentsView, true, true, 'My Appointments'));
