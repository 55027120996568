import React from 'react';
import images from 'constants/images';
import PropTypes from 'prop-types';
import style from './FAQDetailHidden.module.scss';
import Card from 'components/Card/Card';

const FAQDetailCard = () => {
  return (
    <section className={style.hiddenSideSection}>
      <img src={images.brand.fullLogoGrayScale} alt='' />
      <div>
        Click on any question to see the answer details
      </div>
    </section>
  );
};


export default FAQDetailCard;
