import React from 'react';

function Learning() {
  return (
    <svg viewBox='0 0 15.249 16'>
      <g transform='translate(-73.001 -871)'>
        <path
          d='M24,8.761h0A.761.761,0,0,1,24.761,8h6.09a.761.761,0,0,1,.761.761h0a.761.761,0,0,1-.761.761h-6.09A.761.761,0,0,1,24,8.761Z'
          transform='translate(56.637 865.283)'
          fill='#9d9d9d'
        />
        <path
          d='M24,22.761h0A.761.761,0,0,1,24.761,22h6.09a.761.761,0,0,1,.761.761h0a.761.761,0,0,1-.761.761h-6.09A.761.761,0,0,1,24,22.761Z'
          transform='translate(56.637 856.62)'
          fill='#9d9d9d'
        />
        <path
          d='M24,36.761h0A.761.761,0,0,1,24.761,36h6.09a.761.761,0,0,1,.761.761h0a.761.761,0,0,1-.761.761h-6.09A.761.761,0,0,1,24,36.761Z'
          transform='translate(56.637 847.955)'
          fill='#9d9d9d'
        />
        <path
          d='M7.027,4.246l.913,1.561H6.076l.952-1.561M7.027,2a.8.8,0,0,0-.647.381L4.058,6.187a.875.875,0,0,0,0,.761.723.723,0,0,0,.647.381H9.311a.8.8,0,0,0,.647-.381.685.685,0,0,0,0-.761L7.674,2.38A.723.723,0,0,0,7.027,2Z'
          transform='translate(69.029 869)'
          fill='#9d9d9d'
        />
        <path
          d='M8.284,22.567a2.284,2.284,0,1,1,2.284-2.284A2.284,2.284,0,0,1,8.284,22.567Zm0-3.045a.761.761,0,1,0,.761.761A.761.761,0,0,0,8.284,19.522Z'
          transform='translate(67.772 859.098)'
          fill='#9d9d9d'
        />
        <path
          d='M9.806,36.567H6.761A.761.761,0,0,1,6,35.806V32.761A.761.761,0,0,1,6.761,32H9.806a.761.761,0,0,1,.761.761v3.045A.761.761,0,0,1,9.806,36.567ZM7.522,35.045H9.045V33.522H7.522Z'
          transform='translate(67.772 850.433)'
          fill='#9d9d9d'
        />
      </g>
    </svg>
  );
}

export default Learning;
