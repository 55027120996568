import Input from 'components/Input/Input';
import React from 'react';
import propTypes from 'prop-types';
import FormWithEditButton from '../FormWithEditButton/FormWithEditButton';
import CompanyName from 'constants/companyNames';

function PersonalInfoForm({ user, onClick }) {
  console.log(user);
  return (
    <FormWithEditButton onClick={onClick}>
      <form action=''>
        <Input
          label='First Name:'
          placeholder='-'
          value={user.First_Name ? user.First_Name : ''}
          disabled
          autoFocus
        />
        <Input
          label='Last Name:'
          placeholder='-'
          value={user.Last_Name ? user.Last_Name : ''}
          disabled
        />
        <Input
          label={`${CompanyName.short} Email:`}
          placeholder='-'
          value={user.Email ? user.Email : ''}
          disabled
        />
        <Input
          label='Phone:'
          placeholder='-'
          value={user.Phone ? user.Phone : ''}
          disabled
        />
      </form>
    </FormWithEditButton>
  );
}

PersonalInfoForm.propTypes = {
  user: propTypes.object,
  onClick: propTypes.func
};

export default PersonalInfoForm;
