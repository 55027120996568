import Card from 'components/Card/Card';
import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import axios from 'axios';
import { Settings } from '@mui/icons-material';
import style from './DashboardCustomer.module.scss';
import images from 'constants/images';

const DashboardCustomers = ({ refreshUser, setLocalLoader }) => {
  const cancelTokenSource = axios.CancelToken.source();

  useEffect(() => {
    refreshUser(() => setLocalLoader(false), cancelTokenSource);
    return () => {
      cancelTokenSource.cancel('API Call Canceled');
    };
  }, []);
  return (
    <Card title={'Customer Dashboard'} container fill>
      <div className={style.underConstruction}>
        <div className={style.icons}>
          <Settings />

          <img src={images.brand.logo} />
          <Settings />
        </div>
        <p>Customer Dashboard is under development</p>
      </div>
    </Card>
  );
};

DashboardCustomers.propTypes = {
  refreshUser: propTypes.func,
  setLocalLoader: propTypes.func
};

export default DashboardCustomers;
