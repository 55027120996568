import endpoints from 'constants/endpoints';
import { GET_COMMISSIONS, IS_DOWNLOADING_REPORT } from 'constants/types';
import {
  safePostCall,
  safeAWSCallGet,
  safeAWSCallPost
} from 'helpers/firebaseSetup';
import { showAlert } from './site';
// import { wait } from '@testing-library/react';

// action creators
export const setIsDownloadingReport = payload => {
  return {
    type: IS_DOWNLOADING_REPORT,
    payload
  };
};

export const getCommissions =
  (carrier, carrierType) => async (dispatch, getState) => {
    const zohoId = getState().user.zohoId;
    const commissions = getState().commissions.commissions;

    if (!commissions[carrier] || commissions[carrier] === 'Error') {
      let response = await getCommissionsApiCall(zohoId, carrier, carrierType);
      dispatch({
        type: GET_COMMISSIONS,
        payload: { carrier, commissions: response }
      });
    }
  };

const getCommissionsApiCall = (agentId, carrierName, carrierType) =>
  safePostCall(endpoints.awsProxy.safePost, {
    endpoint: endpoints.zoho.getCommissions,
    agentId,
    carrierName,
    carrierType
  })
    .then(commissionsResponse => {
      if (commissionsResponse.data.details.output.includes('Error')) {
        return 'Error';
      } else return JSON.parse(`[${commissionsResponse.data.details.output}]`);
    })
    .catch(error => console.log(error));

export const downloadCommissionsData = (data, carriersOptions) => dispatch => {
  dispatch(setIsDownloadingReport(true));
  dispatch(
    showAlert({ message: 'Running commission statement', code: 'info' })
  );

  // const params = {
  //   carriers: ['BCBS TX'],
  //   agents: ['Andrea Prado', 'John Offutt'],
  //   periods: ['07/24/2023', '07/26/2023']
  // };
  const params = {
    carriers: (data.includeAllCarriers ? carriersOptions : data.carriers)
      .map(x => x.Callidus_Carrier_Names)
      .flat()
      .join(','),
    isAllCarriers: data.includeAllCarriers,
    agents: data.agents.map(x => x.id).join(','),
    agencies: data.agencies.map(x => x.id).join(','),
    periods: data.periods.map(x => x.value).join(',')
  };
  safeAWSCallGet(endpoints.aws.downloadCommissionsData, params, false)
    .then(async response => {
      const data = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
      if (data.ok) {
        dispatch(
          showAlert({ message: 'Downloading CSV data...', code: 'info' })
        );
        // generate date for file name
        let fileDate = new Date()
          .toISOString()
          .replace(/T/, '_') // replace T with a space
          .replace(/:/g, '_') // replace : with
          .replace(/\..+/, ''); // delete the dot and everything after

        await downloadCSV(
          `Commission_Data_${fileDate}.csv`,
          response.data.file
        );
        dispatch(
          showAlert({
            message: 'Data downloaded successfully!',
            code: 'success'
          })
        );
      } else {
        dispatch(
          showAlert({
            message: data.message,
            code: 'error'
          })
        );
      }

      dispatch(setIsDownloadingReport(false));
    })
    .catch(error => {
      console.log(error);
      dispatch(setIsDownloadingReport(false));
      showAlert({
        message:
          'Something went wrong downloading commissions data. Try again later.',
        code: 'error'
      });
    });
};

export const downloadCommissionsData_old =
  (data, carriersOptions) => dispatch => {
    dispatch(setIsDownloadingReport(true));
    dispatch(
      showAlert({ message: 'Running commission statement', code: 'info' })
    );
    safePostCall(endpoints.awsProxy.safePost, {
      endpoint: endpoints.zoho.downloadCommissionsData,
      carrierNames: carriersOptions,
      ...data
    })
      // local testing
      // safePostCall(
      //   'http://localhost:5001/o-neill-marketing-portal/us-central1/safePostApiCall',
      //   {
      //     endpoint: endpoints.zoho.downloadCommissionsDataLocal,
      //     carrierNames: carriersOptions,
      //     ...data
      //   }
      // )
      .then(async ({ data }) => {
        if (data.ok === true) {
          dispatch(
            showAlert({ message: 'Downloading CSV data...', code: 'info' })
          );
          // generate date for file name
          let fileDate = new Date()
            .toISOString()
            .replace(/T/, '_') // replace T with a space
            .replace(/:/g, '_') // replace : with
            .replace(/\..+/, ''); // delete the dot and everything after

          await downloadCSV(`Commission_Data_${fileDate}.csv`, data.data);
          dispatch(
            showAlert({
              message: 'Data downloaded successfully!',
              code: 'success'
            })
          );
        } else {
          dispatch(
            showAlert({
              message: data.message,
              code: 'error'
            })
          );
        }

        dispatch(setIsDownloadingReport(false));
      })
      .catch(error => {
        console.log(error);
        dispatch(setIsDownloadingReport(false));
        showAlert({
          message:
            'Something went wrong downloading commissions data. Try again later.',
          code: 'error'
        });
      });
  };

export const downloadBookOfBusinessData =
  (data, carriersOptions) => dispatch => {
    // Procedures
    const wait = t => {
      return new Promise(resolve => {
        setTimeout(() => resolve(), t);
      });
    };
    const showErrorAlert = msg => {
      console.log('aaa');
      dispatch(setIsDownloadingReport(false));
      dispatch(
        showAlert({
          message: msg,
          code: 'error'
        })
      );
    };
    const catchError = async err => {
      console.log('catched function');
      console.log(err);

      await dispatch(
        showAlert({
          message:
            'Something went wrong downloading commissions data. Try again later.',
          code: 'error'
        })
      );
      await wait(700);
      await dispatch(setIsDownloadingReport(false));
    };
    const userDonwloadCsv = async fileData => {
      // generate date for file name
      let fileDate = new Date()
        .toISOString()
        .replace(/T/, '_') // replace T with a space
        .replace(/:/g, '_') // replace : with
        .replace(/\..+/, ''); // delete the dot and everything after

      await downloadCSV(
        `Book_Of_Business_Data_${fileDate}.csv`,
        fileData
        // response.data.file
      );
      dispatch(
        showAlert({
          message: 'Data downloaded successfully!',
          code: 'success'
        })
      );
      dispatch(setIsDownloadingReport(false));
    };
    const showDownloadingAlert = () => {
      dispatch(setIsDownloadingReport(true));
      dispatch(
        showAlert({ message: 'Running books of business', code: 'info' })
      );
    };
    showDownloadingAlert();

    // prepares request params
    const params = {
      carriers: (data.includeAllCarriers ? carriersOptions : data.carriers)
        .map(x => x.Callidus_Carrier_Names)
        .flat()
        .join(','),
      agents: data.agents.map(x => x.id).join(','),
      agencies: data.agencies.map(x => x.id).join(',')
    };
    const returnCsvDirectly = true;
    if (returnCsvDirectly) {
      safeAWSCallGet(endpoints.aws.downloadBookOfBusinessData, params, false)
        .then(async response => {
          const data = typeof response.data === 'string' ? await JSON.parse(response.data) : response.data;
          if (!data.ok) {
            showErrorAlert(data.message);
            return;
          }
          const build_csv_string = data.file.file.replaceAll('JUMPLINE','\n');
          userDonwloadCsv(build_csv_string);
        })
        .catch(error => catchError(error));
    }
    if (!returnCsvDirectly) {
      // Functions
      const deleteFromServer = async (fileId, sub_files_indexes) => {
        await safeAWSCallGet(
          endpoints.aws.downloadBookOfBusinessDataDelete,
          {
            fileId,
            sub_files_indexes: sub_files_indexes.map(x => String(x)).join(',')
          },
          false
        );
      };
      const downLoadFilePieces = async (fileId, sub_files_indexes) => {
        const generateArrayOfRequests = (fileId, sub_files_indexes) => {
          let requests = [];
          for (let i = 0; i < sub_files_indexes.length; i++) {
            requests.push(
              safeAWSCallGet(
                endpoints.aws.downloadBookOfBusinessDataDownload,
                {
                  chunk_order: sub_files_indexes[i],
                  fileId
                },
                false
              )
            );
          }
          return requests;
        };
        const groupRequests = async (requests, membersInEachGroup) => {
          const request_groups = [];
          let group_count = 0;
          let group_member_count = 0;
          for (let i = 0; i < requests.length; i++) {
            const one_request = requests[i];
            if (group_member_count === 0) {
              request_groups[group_count] = [];
            }
            request_groups[group_count].push(one_request);
            group_member_count++;
            if (group_member_count === membersInEachGroup) {
              group_count++;
              group_member_count = 0;
            }
          }
          return request_groups;
        };
        const checkAllResponsesAreOk = responses_array => {
          const textIsNotEmpty = element =>
            element?.data.file !== '' &&
            element?.data.file !== null &&
            element?.data.file !== undefined;
          const responseIsOk = element => element?.data?.ok;
          return (
            responses_array.every(responseIsOk) &&
            responses_array.every(textIsNotEmpty)
          );
        };
        const makeRequestByGroups = async request_groups => {
          const responses = [];
          for (let i = 0; i < request_groups.length; i++) {
            console.log(
              new Date()
                .toISOString()
                .replace(/T/, '_') // replace T with a space
                .replace(/:/g, '_') // replace : with
                .replace(/\..+/, '')
            );
            const one_group = request_groups[i];
            const group_responses = await Promise.all(one_group);
            const allResponsesAreOk = checkAllResponsesAreOk(group_responses);
            if (!allResponsesAreOk)
              throw new Error('Problem downloading the file');
            await wait(500);
            responses.push(group_responses);
          }
          return responses.flat().map(x => x.data.file);
        };
        // Prepares array of requests/promises
        const requests = generateArrayOfRequests(fileId, sub_files_indexes);
        // Groups requests batches of 5
        const request_groups = await groupRequests(requests, 5);
        // Makes requests in batches of 5
        const csv_text_chunks = await makeRequestByGroups(request_groups);
        // rturns text
        return csv_text_chunks;
      };
      const buildAndDonwloadCsv = async chunks => {
        const re_build_csv_string = chunks.join('');
        userDonwloadCsv(re_build_csv_string);
      };
      const parseResponseData = givenData => {
        const parsedGivenData =
          typeof givenData === 'string' ? JSON.parse(givenData) : givenData;
        if (parsedGivenData.sub_files_indexes) {
          parsedGivenData['sub_files_indexes'] =
            parsedGivenData.sub_files_indexes
              .split(',')
              .map(x => Number(x.trim()));
        }
        if (parsedGivenData.ok) {
          parsedGivenData['ok'] = parsedGivenData.ok === 'true' ? true : false;
        }
        return parsedGivenData;
      };
      // Starts the request
      safeAWSCallPost(endpoints.aws.downloadBookOfBusinessData, params, false)
        .then(async response => {
          // Parses the response
          const responseData = parseResponseData(response.data);

          // Error message if EC2 times out
          const firstRequesTimedOut = String(responseData).includes(
            '504 Gateway Time-out'
          );
          if (firstRequesTimedOut) {
            showErrorAlert('Report took too long to respond, try again.');
            return;
          }

          // Error message if response is not ok
          if (!responseData.ok) {
            showErrorAlert(responseData.message);
            return;
          }

          // Downloads pieces from server
          const responses = await downLoadFilePieces(
            responseData.fileId,
            responseData.sub_files_indexes
          );

          // Download csv to user
          await buildAndDonwloadCsv(responses);

          // Deletes file from server
          await deleteFromServer(
            responseData.fileId,
            responseData.sub_files_indexes
          );
        })
        .catch(async error => {
          catchError(error);
          await deleteFromServer(
            responseData.fileId,
            responseData.sub_files_indexes
          );
        });
    }
  };

async function downloadCSV(filename, data) {
  var blob = new Blob([data]);
  var link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = await filename;
  link.click();
}
