import React, { useState } from 'react';
import style from './notesSection.module.scss';
import propTypes from 'prop-types';
import NoteCard from './NoteCard/NoteCard';
import Button from 'components/Button/Button';
import CreateNoteForm from './CreateNoteForm/CreateNoteForm';
import dateFactory from 'helpers/factory/dateFactory';
import { Add } from '@mui/icons-material';
import Card from 'components/Card/Card';
import { Notes } from '@mui/icons-material';

function NoteSection({ notes = [{}], loading }) {
  const [modalData, setModalData] = useState({
    active: false,
    title: '',
    description: '',
    id: '',
    date: ''
  });

  return (
    <Card
      type='default'
      title='Notes'
      loading={loading}
      options={
        <Button
          size={'xsmall'}
          type={'secondary'}
          text={'Add Note'}
          icon={<Add />}
          onClick={() =>
            setModalData({
              active: true,
              title: '',
              description: '',
              id: ''
            })
          }
        />
      }
      fill
    >
      <div className={style.notesSection}>
        <div className={style.notes}>
          {notes.length === 0 ? (
            <div className={style.emptyNotes}>
              <Notes />
              <p>No notes available</p>
            </div>
          ) : (
            notes.map((note, i) => {
              var { date, ...noteRest } = note;
              //Formats the date
              date = dateFactory(date);
              return (
                <NoteCard
                  key={note.title + i}
                  date={date}
                  {...noteRest}
                  onClick={() =>
                    setModalData({
                      active: true,
                      ...note
                    })
                  }
                />
              );
            })
          )}
        </div>
      </div>
      <CreateNoteForm
        closeModal={() => setModalData({ ...modalData, active: false })}
        {...modalData}
      />
    </Card>
  );
}

NoteSection.propTypes = {
  notes: propTypes.array,
  loading: propTypes.bool
};

export default NoteSection;
