import React from 'react';
import Button from 'components/Button/Button';
import style from './tableSpecialCells.module.scss';
import { Visibility } from '@mui/icons-material';

export function secondaryButton(text, onClick, className, disabled = false) {
  return (
    <Button
      disabled={disabled}
      size='xsmall'
      type='secondary'
      className={`${style.tableButton} ${className}`}
      text={text}
      icon={<Visibility />}
      onClick={onClick}
    />
  );
}
