import React from 'react';

function Close() {
  return (
    <svg viewBox='0 0 12.98 13.041'>
      <path
        d='M23.511,21.48l4.039-3.967a1.515,1.515,0,0,0,.144-1.947,1.37,1.37,0,0,0-2.164-.144L21.491,19.46l-4.039-4.039a1.37,1.37,0,0,0-2.164.144,1.515,1.515,0,0,0,.144,1.947l4.039,3.967-4.039,3.967a1.515,1.515,0,0,0-.144,1.947,1.37,1.37,0,0,0,2.164.144L21.491,23.5l4.039,4.039a1.37,1.37,0,0,0,2.164-.144,1.515,1.515,0,0,0-.144-1.947Z'
        transform='translate(-15.001 -14.959)'
        fill='#6d6d6d'
      />
    </svg>
  );
}

export default Close;
