import {
  CLEAN_COMMISSIONS,
  GET_COMMISSIONS,
  IS_DOWNLOADING_REPORT
} from 'constants/types';

const INITIAL_STATE = {
  commissions: {},
  isDownloadingReport: false
};

function commissionsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_COMMISSIONS:
      return {
        ...state,
        commissions: {
          ...state.commissions,
          [action.payload.carrier]: action.payload.commissions
        }
      };
    case IS_DOWNLOADING_REPORT:
      return {
        ...state,
        isDownloadingReport: action.payload
      };
    case CLEAN_COMMISSIONS:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export default commissionsReducer;
