export const notificationsObjectFactory = notifications => {
  let notificationsObj = {};
  notifications.map(notification => {
    notification.categories.map(category => {
      notificationsObj[category] = {
        notification: notification.notification,
        type: notification.type
      };
    });
  });
  return notificationsObj;
};
