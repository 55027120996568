import React from 'react';

function CarrierResources() {
  return (
    <svg viewBox='0 0 14.829 18.125'>
      <g transform='translate(-290 -443)'>
        <path
          d='M13.414,3.73c2.183.618,4.572,1.359,5.767,1.771v6.467c0,1.4-1.524,3.872-5.767,6.343C9.172,15.8,7.648,13.369,7.648,11.968V5.5c1.195-.453,3.584-1.153,5.767-1.771m0-1.73S6,4.1,6,4.471v7.5c0,3.79,5.478,7.126,7,8.032a.741.741,0,0,0,.824,0c1.565-.865,7-4.243,7-8.032v-7.5C20.829,4.1,13.414,2,13.414,2Z'
          transform='translate(284 441)'
          fill='#9d9d9d'
        />
        <path
          d='M16.307,21.049l-2.06-2.018a.865.865,0,0,1-.082-1.112.783.783,0,0,1,1.236-.082l1.483,1.483,3.954-3.954a.824.824,0,0,1,1.153,1.153l-4.531,4.531a.783.783,0,0,1-1.153,0Z'
          transform='translate(279.295 433.801)'
          fill='#9d9d9d'
        />
      </g>
    </svg>
  );
}

export default CarrierResources;
