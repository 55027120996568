import React from 'react';
import style from './myAppointmentsSection.module.scss';
import propTypes from 'prop-types';

function MyAppointmentsSection({ children }) {
  return (
    <div className={style.myAppointmentsSection}>
      <div className={style.children}>{children}</div>
    </div>
  );
}

MyAppointmentsSection.propTypes = {
  children: propTypes.array
};

export default MyAppointmentsSection;
