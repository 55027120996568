import React, { useEffect, useRef, useState } from 'react';
import InputWithCopyButton from 'components/InputWithCopyButton/InputWithCopyButton';
import style from './agentInfo.module.scss';
import BankAccountForm from './BankAccountForm/BankAccountForm';
import PersonalInfoForm from './PersonalInfoForm/PersonalInfoForm';
import CoverageForm from './CoverageForm/CoverageForm';
import ResidentForm from './ResidentForm/ResidentForm';
import NonResidenceForm from './NonResidenceForm/NonResidenceForm';
import { useSelector } from 'react-redux';
import images from 'constants/images';
import propTypes from 'prop-types';
import Modal from 'components/Modal/Modal';
import SendEmailConfirmationForm from '../../components/SendEmailConfirmationForm/SendEmailConfirmationForm';
import axios from 'axios';
import Edit from 'components/svg/icons/Edit';
import imageCompression from 'browser-image-compression';
import { getBase64 } from 'helpers/imageProcessing';
import { sendEmailModifyAgentData } from 'state/actions/actions';
import Card from 'components/Card/Card';
import getCompanyVariables from 'helpers/getCompanyVariables';
const company = getCompanyVariables();

function AgentInfo({
  refreshUser,
  setLocalLoader,
  zohoData,
  showAlert,
  changeUserProfilePic
}) {
  const user = useSelector(state => state.user.zohoData);
  const [modalState, setModalState] = useState(false);
  const cancelTokenSource = axios.CancelToken.source();
  const positionAtAgency = user.Position_at_Agency;
  const fileInputRef = useRef(null);

  useEffect(() => {
    refreshUser(() => setLocalLoader(false), cancelTokenSource);
    return () => {
      cancelTokenSource.cancel('API Call Canceled');
    };
  }, []);

  const setProfilePicFile = async e => {
    const options = {
      maxSizeMB: 0.02,
      maxWidthOrHeight: 500,
      useWebWorker: true
    };
    let target = e.target;

    if (!target.files[0].type.includes('image'))
      showAlert({ message: 'File not valid as Profile Pic', code: 'error' });
    else {
      showAlert({ message: 'Updating Profile Photo', code: 'info' });

      const compressedFile = await imageCompression(target.files[0], options);

      let promise = getBase64(compressedFile);
      promise.then(result => {
        changeUserProfilePic(
          result.split(',')[1],
          zohoData.id,
          fileInputRef,
          setLocalLoader,
          cancelTokenSource
        );
      });
    }
  };
  const agentNPN = zohoData.NPN;
  const handleResidentFormClick = () => {
    if (company === 'sunshine') {
      window.open(`https://forms.zohopublic.com/sunshinelifehealthadvisors/form/FormUpload/formperma/GEa4stU9T5t0ce2TFm64Ou7qJL2l1iF8efi2zy26IZ8?npn=${agentNPN}`, '_blank');
    } else {
      setModalState(true); // Si no, sigue con el modal
    }
  };
  return (
    <>
      <div className={style.agentInfoView}>
        <Card>
          <div className={style.title}>Personal Info</div>
          <section className={style.personalInfo}>
            <div className={style.leftImageContainer}>
              <div className={style.imageContainer}>
                <img
                  src={zohoData.agentPhoto || images.brand.logo}
                  className={`${!zohoData.agentPhoto && style.placeholder}`}
                  alt=''
                />
                <label htmlFor='profilePic' className={style.profilePicLabel}>
                  <Edit />
                  Edit Profile Picture
                </label>
                <input
                  type='file'
                  id='profilePic'
                  className={style.profilePicInput}
                  onChange={setProfilePicFile}
                  ref={fileInputRef}
                />
              </div>
            </div>
            <div>
              <PersonalInfoForm
                user={user}
                onClick={() => setModalState(true)}
              />
              <InputWithCopyButton
                label='NPN:'
                placeholder='-'
                value={user.NPN ? user.NPN : ''}
                disabled
                lightLabel
                noSpacing={true}
              />
              <InputWithCopyButton
                label='FFM ID:'
                placeholder='-'
                value={user.FFMIG_Number ? user.FFMIG_Number : ''}
                disabled
                lightLabel
                noSpacing={true}
              />
              {/* <InputWithCopyButton
                label='FEIN:'
                placeholder='-'
                value={user.FEIN ? user.FEIN : ''}
                disabled
                lightLabel
              /> */}
            </div>
          </section>
        </Card>

        <Card>
          <div className={style.title}>Personal Bank Info</div>
          <section>
            <BankAccountForm user={user} onClick={() => setModalState(true)} position={positionAtAgency} />
          </section>
        </Card>
        <Card>
        <div className={style.title}>E&O Coverage</div>
        <section className={style.coverage}>
            <CoverageForm user={user} onClick={() => setModalState(true)}/>
        </section>
        </Card>
        <Card>
          <div className={style.title}>Resident State License Information</div>
          <section className={style.resident}>
            <ResidentForm user={user} onClick={handleResidentFormClick} />
          </section>
        </Card>
        <Card>
          <div className={style.title}>Non-Resident States License Numbers</div>
          <section className={style.nonResidence}>
             <NonResidenceForm user={user} onClick={() => setModalState(true)} /> 
          </section>
        </Card>
      </div>
      <Modal
        active={modalState}
        className={style.modal}
        closeModal={() => setModalState(false)}
      >
        <SendEmailConfirmationForm
          closeModal={() => setModalState(false)}
          modalText={
            'We will send you an email with links to securely update your information. Please click "Send" below.'
          }
          dispatchFunction={sendEmailModifyAgentData}
        />
      </Modal>
    </>
  );
}

AgentInfo.propTypes = {
  refreshUser: propTypes.func,
  setLocalLoader: propTypes.func,
  showAlert: propTypes.func,
  changeUserProfilePic: propTypes.func,
  zohoData: propTypes.object
};

export default AgentInfo;
