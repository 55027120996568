import {
  HIDE_ALERT,
  IS_LOADED_GLOBAL,
  IS_LOADING_GLOBAL,
  SHOW_ALERT,
  TOGGLE_SIDEBAR,
  SHOW_ALERT_MODAL,
  HIDE_ALERT_MODAL
} from 'constants/types';

export const siteLoaded = () => dispatch => {
  dispatch({ type: IS_LOADED_GLOBAL });
};

export const siteLoading = () => dispatch => {
  dispatch({ type: IS_LOADING_GLOBAL });
};

export const showAlert = alertData => dispatch => {
  dispatch({ type: SHOW_ALERT, payload: alertData });
};

export const showAlertModal = alertData => dispatch => {
  dispatch({ type: SHOW_ALERT_MODAL, payload: alertData });
};

export const hideAlert = () => dispatch => {
  dispatch({ type: HIDE_ALERT });
};

export const hideAlertModal = () => dispatch => {
  dispatch({ type: HIDE_ALERT_MODAL });
};

export const toggleSidebar = state => dispatch => {
  dispatch({ type: TOGGLE_SIDEBAR, payload: state });
};
