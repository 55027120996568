import withLocalLoader from 'components/HOCs/withLocalLoader';
import { connect } from 'react-redux';
import DashboardCustomers from './DashboardCustomers';
import { refreshUser } from 'state/actions/actions';

const mapStateToProps = () => ({
  DashboardCustomers: []
});

export default connect(mapStateToProps, { refreshUser })(
  withLocalLoader(DashboardCustomers, false, true, 'Customers')
);
