import validator from 'helpers/validator';

function validateLogin(data) {
  let errors = {};
  if (!validator.isEmail(data.email)) {
    errors.email = 'Please enter a valid e-mail';
  }
  if (validator.isEmpty(data.password)) {
    errors.password = 'Please enter a valid password';
  }

  return { errors, isValid: validator.isEmpty(errors) };
}

export default validateLogin;
