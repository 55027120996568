//Formats date into MM/DD/YYYY
function dateFactory(date = '') {
  if (date) {
    let dateTime;
    /*
    Separates date and time (in case is a date-time field)
    if not, it only takes the date value
    */
    if (date.includes('T')) dateTime = date.split('T');
    else dateTime = [date];
    //Splits the date (month, day, year) and forms the new one
    const dateArr = dateTime[0].split('-'),
      newDate = `${dateArr[1]}/${dateArr[2]}/${dateArr[0]}`;
    return newDate;
  }
}

export default dateFactory;
