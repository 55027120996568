import React, { useEffect } from 'react';
import Button from 'components/Button/Button';
import MainSectionCarriers from 'components/MainSectionCarriers/MainSectionCarriers';
import Tabs from 'components/Tabs/Tabs';
import { useDispatch, useSelector } from 'react-redux';
import CommissionCard from '../CommissionCard/CommissionCard';
import style from './bonusView.module.scss';
import { useState } from 'react';
import HiddenSideSection from 'views/MyAppointments/HiddenSideSection/HiddenSideSection';
import { getBonus } from 'state/actions/bonus';
import withLocalLoader from 'components/HOCs/withLocalLoader';
import { getAppointmentsViewData } from 'state/actions/actions';
import propTypes from 'prop-types';
import axios from 'axios';
import Card from 'components/Card/Card';
import { ArrowBack, OpenInNew } from '@mui/icons-material';
import LoadingContent from 'components/Loader/LoadingContante/LoadingContent';
import { Tooltip } from '@mui/material';

function BonusView({ localLoader, setLocalLoader }) {
  const dispatch = useDispatch();
  const bonus = useSelector(state => state.bonus.bonus);
  const { carriers, appointedCarriers } = useSelector(
    state => state.appointments
  );
  const [bonusLoader, setBonusLoader] = useState({
    isActive: false,
    isHidden: null
  });
  const [active, setActive] = useState('');
  const cancelTokenSource = axios.CancelToken.source();
  const [floatingTabs, setFloatingTabs] = useState(false);

  const setActiveCarrierAndGetBonus = carrierName => {
    if (!bonusLoader.isActive) {
      dispatch(getBonus(carrierName, setBonusLoader));
      setActive(carrierName);
    }
  };

  useEffect(() => {
    dispatch(getAppointmentsViewData(setLocalLoader, cancelTokenSource,true));
    return () => {
      cancelTokenSource.cancel('API Call Canceled');
    };
  }, []);

  const handleScroll = e => {
    if (e.target.scrollTop > 180) {
      setFloatingTabs(true);
    } else {
      setFloatingTabs(false);
    }
  };

  const handleOpenLink = url => {
    window.open(url, 'blank');
  };

  const handleResetActiveCarrier = () => {
    setActive('');
  };

  return (
    <div
      onScrollCapture={handleScroll}
      className={`${style.bonus} ${active ? style.isActive : ''} `}
    >
      <div className={style.bonusItem}>
        <Card
          fill
          title='Commission Bonuses'
          options={
            <>
              <Button
                type='secondary'
                size='small'
                rounded={'large'}
                text='Go to AgencyBloc'
                icon={<OpenInNew />}
                onClick={() => handleOnClick('https://app.agencybloc.com/')}
              />
              {/* <Button
                type='primaryLight'
                size='small'
                rounded={'large'}
                text='Request Appointments'
                onClick={() =>
                  handleOnClick(
                    `https://forms.oneillmarketing.net/oneillmarketing/form/IndividualAppointmentRequestForm/formperma/cJer2y2EfRB6W8F6Al74YPryxKlkdl1kzBnw1hH1eik?npnField98221=${
                      user.NPN_Text_Field
                    }&ffm=${user.ffm_ms[0] && user.ffm_ms[0]}&ahip=${
                      user.AHIP[0] && user.AHIP[0]
                    }`
                  )
                }
              /> */}
            </>
          }
        >
          <Tabs
            floatingHeader={floatingTabs}
            headers={['Appointed Carriers', 'All Carriers']}
            className={style.tabs}
          >
            <MainSectionCarriers
              Card={CommissionCard}
              CardNoStates={CommissionCard}
              carriers={appointedCarriers}
              active={active}
              localLoader={localLoader}
              setActive={setActiveCarrierAndGetBonus}
            />
            <MainSectionCarriers
              Card={CommissionCard}
              CardNoStates={CommissionCard}
              carriers={carriers}
              active={active}
              localLoader={localLoader}
              setActive={setActiveCarrierAndGetBonus}
            />
          </Tabs>
        </Card>
      </div>
      <div className={style.detailsItem}>
        {active && !bonusLoader.isHidden ? (
          <Card
            fill
            title={`${active}: Bonus Breakdown`}
            leftOptions={
              <Button
                size='small'
                type='default'
                icon={<ArrowBack />}
                rounded='large'
                outlined
                onClick={handleResetActiveCarrier}
              />
            }
          >
            <div className={style.sideSection}>
              {bonusLoader.isActive ? (
                <LoadingContent />
              ) : (
                bonus[active] &&
                bonus[active].map((bonusElement, i) => (
                  <div className={style.bonusCard} key={i}>
                    <p>{Object.keys(bonusElement)[0]}</p>

                    <Button
                      type='secondary'
                      size='small'
                      text='view bonus'
                      icon={<OpenInNew />}
                      onClick={() =>
                        handleOpenLink(Object.values(bonusElement)[0])
                      }
                    />
                  </div>
                ))
              )}
            </div>
          </Card>
        ) : (
          <>
            <HiddenSideSection
              text={
                bonusLoader.isHidden
                  ? 'No Information Found for this Carrier'
                  : null
              }
            />
            {active && (
              <div className={style.closeButton}>
                <Tooltip title={'Close'}>
                  <div>
                    <Button
                      size='xsmall'
                      type='default'
                      icon={<ArrowBack />}
                      text={'Close'}
                      rounded='large'
                      outlined
                      onClick={handleResetActiveCarrier}
                    />
                  </div>
                </Tooltip>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

BonusView.propTypes = {
  localLoader: propTypes.bool,
  setLocalLoader: propTypes.func
};

export default withLocalLoader(BonusView, true, true, 'Commissions', 'Bonuses');
