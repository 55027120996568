import React from 'react';

function Marketing() {
  return (
    <svg viewBox='0 0 19.845 16.231'>
      <g transform='translate(-1.998 -6)'>
        <path
          d='M16.427,6h-.271L2.63,9.968a.9.9,0,0,0-.631.9v4.058a.992.992,0,0,0,.631.9l2.119.586.721,4.644a1.308,1.308,0,0,0,1.308,1.172H8.807A1.443,1.443,0,0,0,9.8,21.78a1.488,1.488,0,0,0,.271-1.172l-.5-2.75L16.156,19.8h.271a.9.9,0,0,0,.9-.9V6.9A.9.9,0,0,0,16.427,6ZM8.221,20.428H7.184L6.643,17l.947.271Zm7.3-2.705L3.8,14.251V11.546L15.525,8.119Z'
          transform='translate(0)'
          fill='#9d9d9d'
        />
        <path
          d='M38.893,12a.676.676,0,0,0,.406-.09l1.8-.9a.907.907,0,1,0-.812-1.623l-1.8.9a.9.9,0,0,0-.406,1.217.947.947,0,0,0,.812.5Z'
          transform='translate(-19.761 -1.805)'
          fill='#9d9d9d'
        />
        <path
          d='M41.1,28.388l-1.8-.9a.947.947,0,0,0-1.217.361.9.9,0,0,0,.406,1.217l1.8.9a.676.676,0,0,0,.406.09.947.947,0,0,0,.812-.5.857.857,0,0,0-.406-1.172Z'
          transform='translate(-19.761 -11.748)'
          fill='#9d9d9d'
        />
        <path
          d='M38.9,21.1h1.8a.9.9,0,0,0,0-1.8H38.9a.9.9,0,1,0,0,1.8Z'
          transform='translate(-19.77 -7.303)'
          fill='#9d9d9d'
        />
      </g>
    </svg>
  );
}

export default Marketing;
