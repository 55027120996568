import dateFactory from './dateFactory';

export const leadFactory = lead => {
  return {
    id: lead.id,
    name: lead.Name1,
    dateOfBirth: lead.Date_Of_Birth,
    phone: lead.Phone,
    email: lead.Email,
    status: lead.Status,
    note: lead.Note ? lead.Note : '',
    type: lead.Type,
    created: dateFactory(lead.Created_Time),
    modified: dateFactory(lead.Modified_Time),
    responseTime: lead.Agent_Response_Time,
    hasBeenClosed: lead.Has_been_closed,
    sourcePortal: lead.Source_for_Agents_Portal,
    category: lead.Category
  };
};
