import images from 'constants/images';
import React from 'react';
import style from './footer.module.scss';

function Footer() {
  return (
    <div className={style.footer}>
      <span />
      {/*<img src={images.brand.fullLogoGrayScale} alt='' />*/}
    </div>
  );
}

export default Footer;
