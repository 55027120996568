import getCompanyVariables from 'helpers/getCompanyVariables';

const company = getCompanyVariables();
let CompanyName = {};
switch (company) {
    case 'oneill':
      CompanyName = {
        'fullName' : 'O\'Neill Marketing',
        'acronym' : 'OM',
        'short': 'O\'Neill'
      };
      break;
    case 'sunshine':
      CompanyName = {
        'fullName' : 'Sunshine Life and Health Advisors',
        'acronym' : 'SLHA',
        'short': 'Sunshine',
      };
      break;

  }

  export default CompanyName;