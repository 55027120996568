export function ArrayToObject(arr, key = 'id') {
  let auxObj = {};
  arr.forEach(element => {
    auxObj[element[key]] = element;
  });
  return auxObj;
}

export function arrayDifference([...arr1] = [], [...arr2] = []) {
  return arr1.filter(element => !arr2.includes(element));
}
