import React from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import style from './leadsTable.module.scss';
import propTypes from 'prop-types';
import { useEffect } from 'react';
import { useState } from 'react';
import { secondaryButton } from 'helpers/tableSpecialCells/tableSpecialCells';
import Validator from 'helpers/validator';
import { stringTrimmer } from 'helpers/strings';

function LeadsTable({ leads, setActive = () => {}, localLoader }) {
  const [tableData, setTableData] = useState({ pageSize: 0, data: [] });

  useEffect(() => {
    const pageSize = leads.length > 10 ? 10 : leads.length;
    setTableData({
      pageSize,
      data: [...leads]
    });
  }, [leads]);

  const columns = useMemo(
    () => [
      {
        header: 'Created',
        accessorKey: 'created',
        Cell: rowProps => expiredDate(rowProps.row.original, 'created'),
      },
      {
        header: 'Created',
        accessorKey: 'created',
        Cell: rowProps => cellContainer(rowProps.row.original.created),
      },
      {
        header: 'Type',
        accessorKey: 'type',
        Cell: rowProps => typeCell(rowProps.row.original.type),
      },
      {
        header: 'Name',
        accessorKey: 'name',
        Cell: rowProps => cellContainer(stringTrimmer(rowProps.row.original.name, 20)),
      },
      {
        header: 'DOB',
        accessorKey: 'dateOfBirth',
        Cell: rowProps => cellContainer(rowProps.row.original.dateOfBirth),
      },
      {
        header: 'Email',
        accessorKey: 'email',
        Cell: rowProps =>
          cellContainer(stringTrimmer(rowProps.row.original.email, 20)),
      },
      {
        header: 'Phone',
        accessorKey: 'phone',
        Cell: rowProps => cellContainer(rowProps.row.original.phone),
      },
      {
        header: 'Status',
        accessorKey: 'status',
        Cell: rowProps => statusCell(rowProps.row.original.status),
      },
      {
        header: 'Notes',
        accessorKey: 'notes',
        Cell: rowProps =>
          secondaryButton(
            'Details',
            () => setActive(rowProps.row.original.id),
            style.button
          )
      }
    ],
    [],
  );
  const table = useMaterialReactTable({
    columns,
    data: [...tableData.data],
    enableSorting: false,
    enableColumnActions: false,
    renderEmptyRowsFallback: () => 
      <h4
              style={{
                color: 'grey',
                textAlign: 'center',
                fontWeight: '400'
              }}
            >
              No Action items
          </h4>
  });

  /*
  return tableData.data.length === 0 ? (
    <MaterialTable
      localization={{
        body: {
          emptyDataSourceMessage: (
            <h4
              style={{
                color: 'grey',
                textAlign: 'center',
                fontWeight: '400'
              }}
            >
              No leads Assigned
            </h4>
          )
        }
      }}
      options={{
        pageSize: 1,
        pageSizeOptions: [],
        showTitle: false
      }}
      columns={[
        {
          title: 'Created',
          field: 'created'
        },
        {
          title: 'Type',
          field: 'type'
        },
        {
          title: 'Name',
          field: 'name'
        },
        {
          title: 'DOB',
          field: 'dateOfBirth'
        },
        {
          title: 'Email',
          field: 'email'
        },
        {
          title: 'Phone',
          field: 'phone'
        },
        {
          title: 'Status',
          field: 'status'
        },
        {
          title: 'Notes',
          field: 'notes'
        }
      ]}
      //Row props = data
      data={[]}
    />
  ) : (
    <>
      {' '}
      <MaterialTable
        isLoading={localLoader}
        options={{
          searchAutoFocus: true,
          pageSize: tableData.pageSize,
          pageSizeOptions: [],
          showTitle: false
        }}
        columns={[
          {
            title: 'Created',
            field: 'created',
            render: rowProps => cellContainer(rowProps.created),
            cellStyle: {
              width: '1%'
            }
          },
          {
            title: 'Type',
            field: 'type',
            render: rowProps => typeCell(rowProps.type),
            cellStyle: {
              width: '15%'
            }
          },
          {
            title: 'Name',
            field: 'name',
            render: rowProps => cellContainer(stringTrimmer(rowProps.name, 20)),
            cellStyle: {
              width: '35%'
            }
          },
          {
            title: 'DOB',
            field: 'dateOfBirth',
            render: rowProps => cellContainer(rowProps.dateOfBirth),
            cellStyle: {
              width: '20%'
            }
          },
          {
            title: 'Email',
            field: 'email',
            render: rowProps =>
              cellContainer(stringTrimmer(rowProps.email, 20)),
            cellStyle: {
              width: '1%'
            }
          },
          {
            title: 'Phone',
            field: 'phone',
            render: rowProps => cellContainer(rowProps.phone),
            cellStyle: {
              width: '15%'
            }
          },
          {
            title: 'Status',
            field: 'status',
            render: rowProps => statusCell(rowProps.status),
            cellStyle: {
              width: '25%'
            }
          },
          {
            title: 'Notes',
            field: 'notes',
            render: rowProps =>
              secondaryButton(
                'Details',
                () => setActive(rowProps.id),
                style.button
              ),
            cellStyle: {
              width: '10%'
            }
          }
        ]}
        //Row props = data
        data={[...tableData.data]}
      />
    </>
  );
  */
}

LeadsTable.propTypes = {
  leads: propTypes.array,
  setActive: propTypes.func,
  localLoader: propTypes.bool
};

export default LeadsTable;

const statusStyling = {
  'Attempted To Contact': 0,
  Contacted: 1,
  'Not Contacted': 2,
  'Not Interested': 3,
  'Closed Won': 1,
  'Closed Lost': 3,
  stylings: {
    0: style.info,
    1: style.success,
    2: style.warning,
    3: style.failed
  }
};

const cellContainer = text => {
  return <span className={style.cellContainer}>{text}</span>;
};

const statusCell = text => {
  return (
    <p
      className={`${style.cellContainer} ${
        statusStyling.stylings[statusStyling[text]]
      }`}
    >
      {text}
    </p>
  );
};

const typeCell = text => {
  if (Validator.isEmpty(text)) return null;
  let classObj = '';
  text === 'High Priority'
    ? (classObj = 'Attempted To Contact')
    : (classObj = 'Contacted');
  return (
    <p
      className={`${style.cellContainer} ${
        statusStyling.stylings[statusStyling[classObj]]
      }`}
    >
      {text}
    </p>
  );
};
