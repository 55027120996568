import endpoints from 'constants/endpoints';
import { GET_FAQS,GET_CARRIER_FAQS,SET_CARRIER_FAQS_LOADER,CLEAR_CARRIER_FAQS} from 'constants/types';
import { ArrayToObject } from 'helpers/arrays';
import { safePostCall } from 'helpers/firebaseSetup';
import { siteLoaded } from './site';
import { questionFactory } from 'helpers/factory/questionFactory';
import { bool } from 'prop-types';

export const getFAQsData =
  (setLocalLoader = () => {}, cancelTokenSource = {}) =>
  async (dispatch, getState) => {
    setLocalLoader(true);
    console.log('Fetching FAQs data');
    console.log(endpoints.zoho.getFAQS);
    safePostCall(
      endpoints.awsProxy.safePost,
      {
        endpoint: endpoints.zoho.getFAQS
      },
      { cancelToken: cancelTokenSource.token }
    )
      .then(faqsRes => {
        console.log('Response:');
        console.log(faqsRes.data.details.output);
        if (faqsRes) {
          const response = faqsRes.data.details.output;
          const faqsArray = JSON.parse(response).faqsArray;
          const transformedResponse = faqsArray.map(item => ({
            type: item.type,
            questions: item.questions.map(question => questionFactory(question))
          }));

          console.log('Transformed response:', transformedResponse);


          setLocalLoader(false);
          dispatch({
            type: GET_FAQS,
            payload: transformedResponse
          });
        } else {
          setLocalLoader(false);
          // Handle the case where the response structure is unexpected
          console.error('Unexpected response structure', faqsRes);
        }
      })
      .catch(error => {
        console.error(error);
        if (!(error?.message === 'API Call Canceled')) {
          setLocalLoader(false);
        }
      });
  };

  export const getCarrierFaqs =
  (setLocalLoader = bool, cancelTokenSource = {},carrierId = '') =>
    async (dispatch, getState) => {
      dispatch({
        type: CLEAR_CARRIER_FAQS,
        payload: true
      });
      dispatch({
        type: SET_CARRIER_FAQS_LOADER,
        payload: true
      });
      safePostCall(
        endpoints.awsProxy.safePost,
        {
          carrierId,
          endpoint: endpoints.zoho.getCarrierFAQS
        },
        { cancelToken: cancelTokenSource.token }
      )
      .then(carrierfaqsRes => {
        if (carrierfaqsRes) {
          const response = carrierfaqsRes.data.details.output;
          const faqsArray = JSON.parse(response).carrierFaqs;
          let carrierFaqsFormatted = faqsArray.map(question => questionFactory(question));
          dispatch({
            type: GET_CARRIER_FAQS,
            payload: carrierFaqsFormatted
          });
          dispatch({
            type: SET_CARRIER_FAQS_LOADER,
            payload: false
          });
        } else {
          dispatch({
            type: SET_CARRIER_FAQS_LOADER,
            payload: false
          });
          // Handle the case where the response structure is unexpected
          console.error('Unexpected response structure', carrierFaqsFormatted);
        }
      })
      .catch(error => {
        console.error(error);
        if (!(error?.message === 'API Call Canceled')) {
          dispatch({
            type: SET_CARRIER_FAQS_LOADER,
            payload: false
          });;
        }
      });
    };
    