import withLocalLoader from 'components/HOCs/withLocalLoader';
import { connect } from 'react-redux';
import FaqsView from './faqsView';
import {
    getFAQsData,
  } from 'state/actions/actions';

function mapStateToProps({faqs}) {
    return {
      faqs: faqs.faqsArray
    };
  }
export default connect(mapStateToProps, {getFAQsData})(withLocalLoader(FaqsView, true, true, 'FAQs'));
  