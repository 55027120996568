import React, { useEffect, useState } from 'react';
//HELPERS
import images from 'constants/images';

//EXTERNALS
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import ToggleSideBar from '../ToggleSideBar/ToggleSideBar';
import { toggleSidebar } from 'state/actions/site';
import SidebarContent from './SidebarContainer/SidebarContent';
import companyName from 'constants/companyNames';
import styleSunshine from './sidebarSunshine.module.scss';
import style from './sidebar.module.scss';


function Sidebar({ activePage }) {
  let styleSelected = '';
  const sidebarExpanded = useSelector(
    state => state.site.sidebarExpanded,
    shallowEqual
  );
  const dispatch = useDispatch();

  const [isMobile] = useState(false);

  const handleCloseSidebarMobile = () => {
    dispatch(toggleSidebar(false));
    localStorage.setItem('menuState', JSON.stringify(false));
  };

  useEffect(() => {
    const menuState = localStorage.getItem('menuState');
    if (menuState) {
      dispatch(toggleSidebar(JSON.parse(menuState)));
    }
  }, []);
  switch (companyName.short) {
    case 'O\'Neill':
      styleSelected = style;
      break;
    case 'Sunshine':
      styleSelected = styleSunshine;
      break;
  }
  return (
    <div
      className={`${styleSelected.sidebarContainer}  ${
        sidebarExpanded ? '' : styleSelected.sideNavShrinkedMobile
      }`}
    >
      <div
        className={styleSelected.backDropSideBar}
        onClick={handleCloseSidebarMobile}
      ></div>
      <div
        className={`${styleSelected.sidebar}  ${
          sidebarExpanded ? '' : isMobile ? '' : styleSelected.sideNavShrinked
        }`}
      >
        <div className={styleSelected.header}>
          <ToggleSideBar />
          <div>
            <img src={images.brand.fullLogo} alt='' />
            <span> {companyName.acronym} Portal v{process.env.REACT_APP_VERSION}</span>
          </div>
        </div>

        <SidebarContent activePage={activePage} />
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  activePage: propTypes.string
};

export default Sidebar;
