import validator from 'helpers/validator';

function validateNote(data) {
  let errors = {};
  if (validator.isEmpty(data.title)) {
    errors.title = 'Please enter a title';
  }
  if (validator.isEmpty(data.description)) {
    errors.description = 'Please enter a description';
  }

  return { errors, isValid: validator.isEmpty(errors) };
}

export default validateNote;
