import React, { useEffect } from 'react';
import style from './videos.module.scss';
import propTypes from 'prop-types';

function Videos({ videoFolder, setLocalLoader }) {
  useEffect(() => {
    setLocalLoader(false);
  }, []);

  return (
    <div className={style.videos}>
      <iframe
        src={videoFolder}
        scrolling='no'
        frameBorder='0'
        allowFullScreen={true}
        width='100%'
      />
    </div>
  );
}

Videos.propTypes = {
  videoFolder: propTypes.string,
  setLocalLoader: propTypes.func
};

export default Videos;
