import React from 'react';

function Info() {
  return (
    <svg viewBox='0 0 15.987 15.987'>
      <g transform='translate(0 0)'>
        <path
          d='M9.993,2a7.957,7.957,0,1,0,5.663,2.33A7.993,7.993,0,0,0,9.993,2Zm0,14.533a6.54,6.54,0,1,1,6.54-6.54A6.54,6.54,0,0,1,9.993,16.533Z'
          transform='translate(-2 -2)'
          fill='#959595'
        />
        <path
          d='M22.75,20a.75.75,0,0,0-.75.75v4.5a.75.75,0,0,0,1.5,0v-4.5A.75.75,0,0,0,22.75,20Z'
          transform='translate(-15 -13.46)'
          fill='#959595'
        />
        <circle
          cx='0.75'
          cy='0.75'
          r='0.75'
          transform='translate(7 4.54)'
          fill='#959595'
        />
        <path
          d='M.75,0A.75.75,0,1,1,0,.75.75.75,0,0,1,.75,0Z'
          transform='translate(7 4.54)'
          fill='#959595'
        />
      </g>
    </svg>
  );
}

export default Info;
