import { CLEAN_BONUS, GET_BONUS } from 'constants/types';

const INITIAL_STATE = {
  bonus: {}
};

function bonusReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_BONUS:
      return {
        ...state,
        bonus: {
          ...state.bonus,
          [action.payload.carrierName]: action.payload.bonus
        }
      };
    case CLEAN_BONUS:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export default bonusReducer;
