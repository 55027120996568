import React from 'react';

function Web() {
  return (
    <svg viewBox='0 0 17.048 17.048'>
      <path
        d='M10.524,2a8.485,8.485,0,1,0,6.039,2.485A8.524,8.524,0,0,0,10.524,2Zm6.238,5.424h-1.9a10.771,10.771,0,0,0-1.162-3.1,7.168,7.168,0,0,1,3.061,3.1Zm.736,3.1a6.78,6.78,0,0,1-.194,1.55H15.1c.039-.5.077-1.007.077-1.55s-.039-1.046-.077-1.55H17.3a6.78,6.78,0,0,1,.194,1.55Zm-13.948,0a6.78,6.78,0,0,1,.194-1.55H5.952c-.039.5-.077,1.007-.077,1.55s.039,1.046.077,1.55H3.744a6.78,6.78,0,0,1-.194-1.55Zm3.874,0c0-.542.039-1.046.077-1.55H9.749v3.1H7.5C7.463,11.57,7.424,11.066,7.424,10.524Zm3.874-6.7a4.533,4.533,0,0,1,1.162,1.434,8.408,8.408,0,0,1,.814,2.17H11.3Zm-1.55,0v3.6H7.773a8.408,8.408,0,0,1,.814-2.17A4.533,4.533,0,0,1,9.749,3.821Zm0,9.8v3.6a4.533,4.533,0,0,1-1.162-1.434,8.408,8.408,0,0,1-.814-2.17Zm1.55,3.6v-3.6h1.976a8.408,8.408,0,0,1-.814,2.17A4.533,4.533,0,0,1,11.3,17.227Zm0-5.153v-3.1h2.247c.039.5.077,1.007.077,1.55s-.039,1.046-.077,1.55ZM7.347,4.325a10.771,10.771,0,0,0-1.162,3.1h-1.9a7.168,7.168,0,0,1,3.061-3.1Zm-3.061,9.3h1.9a10.771,10.771,0,0,0,1.162,3.1,7.168,7.168,0,0,1-3.061-3.1Zm9.415,3.1a10.771,10.771,0,0,0,1.162-3.1h1.9A7.168,7.168,0,0,1,13.7,16.723Z'
        transform='translate(-2 -2)'
        fill='#959595'
      />
    </svg>
  );
}

export default Web;
