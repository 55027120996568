import React from 'react';
import CollapsibleList from 'components/CollapsibleList/CollapsibleList';
import CollapsibleSection from 'components/CollapsibleList/CollapsibleSection';
import { Dashboard, Settings } from '@mui/icons-material';
import CollapsibleItem from 'components/CollapsibleList/CollapsibleItem';
import PropTypes from 'prop-types';

const renderChilds = (Member_Agencies, thereIsOnlyOneAgencyAtAll) => {
  console.log('children component');
  const childs = [];
  Member_Agencies.map(agency => {
    if (agency.Member_Agencies.length > 0) {
      console.log('if 1');
      // render collapsible section
      childs.push(
        <CollapsibleSection
          key={`agency_${agency.AgencyID}`}
          id={`${agency.AgencyID}`}
          title={agency.AgencyName}
          // check for O'Neill Marketing agency ID and set red color
          highlightFirst={
            agency.AgencyID === '4738157000010316014' ? true : false
          }
          titleColor='secondary'
          expanded
          showOptionsOnHover
          optionsArray={[
            // empty space
            {
              text: 'Dashboard',
              icon: <Dashboard />,
              to: `/agencies/${agency.AgencyID}`
            },
            {
              text: 'Settings/Invites',
              icon: <Settings />,
              to: `/agencies/${agency.AgencyID}/settings`
            }
          ]}
        >
          {/* Render childs */}
          {renderChilds(agency.Member_Agencies)}
        </CollapsibleSection>
      );
    } else {
      console.log('else');
      // render normal section
      childs.push(
        <CollapsibleItem
          key={`agency_${agency.AgencyID}`}
          title={`${agency.AgencyName}`}
          optionsArray={[
            {
              text: 'Dashboard',
              icon: <Dashboard />,
              to: `/agencies/${agency.AgencyID}`
            },
            {
              text: 'Settings/Invites',
              icon: <Settings />,
              to: `/agencies/${agency.AgencyID}/settings`
            }
          ]}
          isOnlyOne={thereIsOnlyOneAgencyAtAll}
        />
      );
    }
  });
  console.log(childs);
  return childs;
};

const CollapsibleListComponent = ({ data }) => {
  // console.log(data);
  const thereIsOnlyOneAgencyAtAll = data.length === 1;
  return (
    <CollapsibleList>
      {data.length > 0 && renderChilds(data, thereIsOnlyOneAgencyAtAll)}
    </CollapsibleList>
  );
};

CollapsibleListComponent.propTypes = {
  data: PropTypes.array
};

export default CollapsibleListComponent;
