import { createStore } from 'redux';
import reducers from '../reducers/combineReducers';
import middlewares from './middlewares';
import { composeWithDevTools } from 'redux-devtools-extension';
import persistState from 'redux-localstorage';

const store = createStore(
  reducers,
  composeWithDevTools(middlewares, persistState(['authentication', 'user']))
);

export default store;
