import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import { useState } from 'react';
import axios from 'axios';
import Modal from 'components/Modal/Modal';
import Tabs from 'components/Tabs/Tabs';
import style from './leads.module.scss';
import LeadsFormModal from './LeadsFormModal/LeadsFormModal';
import LeadsTable from './LeadsTable/LeadsTable';
import TableContainerLeads from './TableContainerLeads';
import Button from 'components/Button/Button';
import { buttonStyling } from 'constants/enum';
import SendEmailConfirmationForm from 'components/SendEmailConfirmationForm/SendEmailConfirmationForm';
import { sendEmailPurchaseMoreLeads } from 'state/actions/actions';
import Card from 'components/Card/Card';

function Leads({
  leads,
  getLeads,
  setLocalLoader,
  hasDistributionTier,
  ...props
}) {
  const [active, setActive] = useState(null);
  const [sendEmailModal, setSendEmailModal] = useState(false);
  const [leadsArray, setLeadsArray] = useState(Object.values(leads));
  const cancelTokenSource = axios.CancelToken.source();

  useEffect(() => {
    getLeads(setLocalLoader, cancelTokenSource);
    return () => {
      cancelTokenSource.cancel('API Call Canceled');
    };
  }, []);

  useEffect(() => {
    let array = Object.values(leads);
    setLeadsArray([...array]);
  }, [leads]);

  return (
    <>
      <Card container fill title='Leads'>
        <div className={style.leads}>
          {hasDistributionTier ? (
            <Tabs
              size='small'
              headers={[
                'All',
                'Not-Contacted',
                'Attempted to Contact',
                'Contacted',
                'Not Interested',
                'Closed'
              ]}
            >
              <TableContainerLeads
                purchaseMoreLeads={() => setSendEmailModal(true)}
              >
                <LeadsTable
                  leads={leadsArray}
                  setActive={setActive}
                  {...props}
                />
              </TableContainerLeads>
              <TableContainerLeads
                purchaseMoreLeads={() => setSendEmailModal(true)}
              >
                <LeadsTable
                  leads={leadsArray.filter(
                    lead => lead.status === 'Not Contacted'
                  )}
                  pageSize={
                    leadsArray.filter(lead => lead.status === 'Not Contacted')
                      .length
                  }
                  setActive={setActive}
                />
              </TableContainerLeads>
              <TableContainerLeads
                purchaseMoreLeads={() => setSendEmailModal(true)}
              >
                <LeadsTable
                  leads={leadsArray.filter(
                    lead => lead.status === 'Attempted To Contact'
                  )}
                  setActive={setActive}
                />
              </TableContainerLeads>
              <TableContainerLeads
                purchaseMoreLeads={() => setSendEmailModal(true)}
              >
                <LeadsTable
                  leads={leadsArray.filter(lead => lead.status === 'Contacted')}
                  setActive={setActive}
                />
              </TableContainerLeads>
              <TableContainerLeads
                purchaseMoreLeads={() => setSendEmailModal(true)}
              >
                <LeadsTable
                  leads={leadsArray.filter(
                    lead => lead.status === 'Not Interested'
                  )}
                  setActive={setActive}
                />
              </TableContainerLeads>
              <TableContainerLeads
                purchaseMoreLeads={() => setSendEmailModal(true)}
              >
                <LeadsTable
                  leads={leadsArray.filter(
                    lead => lead.status && lead.status.includes('Closed')
                  )}
                  setActive={setActive}
                />
              </TableContainerLeads>
            </Tabs>
          ) : (
            <div className={style.messageContainer}>
              <p>
                You are not registered to our leads program. To sign up follow
                this link:
              </p>
              <Button
                text='Buy more leads'
                className={` ${buttonStyling.Open} ${buttonStyling.hover}`}
                type='secondary'
                onClick={() => setSendEmailModal(true)}
              />
            </div>
          )}
        </div>
      </Card>
      {active && (
        <LeadsFormModal
          active={active ? true : false}
          setModalState={() => setActive(null)}
          {...leads[active]}
        />
      )}

      <Modal
        size='auto'
        active={sendEmailModal}
        className={style.emailModal}
        closeModal={() => setSendEmailModal(false)}
      >
        <SendEmailConfirmationForm
          closeModal={() => setSendEmailModal(false)}
          modalText={
            'We will send you an email with the link to purchase more leads. Please click "Send" below.'
          }
          dispatchFunction={() => sendEmailPurchaseMoreLeads()}
        />
      </Modal>
    </>
  );
}

Leads.propTypes = {
  leads: propTypes.object,
  getLeads: propTypes.func,
  setLocalLoader: propTypes.func,
  hasDistributionTier: propTypes.bool
};

export default Leads;
