import React from 'react';
import PropTypes from 'prop-types';
import style from './FAQDetailCard.module.scss';
import Card from 'components/Card/Card';
import parse from 'html-react-parser';

const FAQDetailCard = ({ question, onBackClick }) => {
  const isYouTubeUrl = (url) => {
    return url.includes('youtube.com') || url.includes('youtu.be');
  };

  const getYouTubeId = (url) => {
    const match = url.match(/(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/)|youtu\.be\/|youtube\.com\/embed\/|youtube\.com\/v\/|youtube\.com\/watch\?v=|youtube\.com\/watch\?.+&v=|youtube\.com\/playlist\?list=)([a-zA-Z0-9_-]{11})/);
    return match ? match[1] : null;
  };

  const answerContent = question.answer ?? 'No answer provided';
  const parsedAnswer = parse(answerContent);
  const videoUrl = question.answerVideo;
  const isYouTube = videoUrl ? isYouTubeUrl(videoUrl) : null;
  const youTubeId = isYouTube ? getYouTubeId(videoUrl) : null;

  return (
    <div className={style.detailContainer}>
      <Card
        withHr={false}
        title="Answer Details:"
        fill
        type='default'
      >
        <p className={style.question}>{question.question}</p>
        <div>
          {parsedAnswer ?
          parsedAnswer: 'No Answer Avilable'}
        </div>
        <img
          src={question.answerImg}
          className={style.img}
        />
        {videoUrl ? (
          <div className={style.videoWrapper}>
            {isYouTube ? (
              <iframe
                src={`https://www.youtube.com/embed/${youTubeId}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            ) : (
              <video controls>
                <source
                  src={videoUrl}
                  type='video/mp4'
                />
              </video>
            )}
          </div>
        ) : null}
        <button className={style.closeButton} onClick={onBackClick}>
          x
        </button>
      </Card>
    </div>
  );
};

FAQDetailCard.propTypes = {
  question: PropTypes.object.isRequired,
  onBackClick: PropTypes.func.isRequired,
};

export default FAQDetailCard;
