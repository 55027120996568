export default function ownerFactory(owner) {
    const ownerObj = {
      role: owner.role.id,
      id: owner.id,
      Name: owner.full_name,
      Phone: owner.phone,
      Email: owner.email,
      zohoBooking: owner.Zoho_Booking_Link,
      imgURL: owner.Img_URL
    };
  return ownerObj;
}