import withLocalLoader from 'components/HOCs/withLocalLoader';
import { connect } from 'react-redux';
import Videos from './Videos';

const mapStateToProps = ({ user }) => ({
  videoFolder: user.zohoData.videoFolder
});

export default connect(
  mapStateToProps,
  {}
)(withLocalLoader(Videos, true, true, 'Videos', 'Videos/Webinars'));
