import React from 'react';

function LogOut() {
  return (
    <svg viewBox='0 0 15.093 15.036'>
      <g transform='translate(-1.85 -2)'>
        <g transform='translate(1.85 2)'>
          <path
            d='M24.73,16.94l-2.7-2.734a.718.718,0,0,0-.923-.068.649.649,0,0,0-.068,1.025l1.572,1.572H14.683a.683.683,0,1,0,0,1.367h7.928L21.04,19.674a.649.649,0,0,0,.068,1.025.718.718,0,0,0,.923-.068l2.7-2.734a.649.649,0,0,0,0-.957Z'
            transform='translate(-9.848 -9.9)'
            fill='#fff'
          />
          <path
            d='M10.786,15.669H9.419a6.151,6.151,0,1,1,0-12.3h1.367a.7.7,0,0,0,.683-.683.82.82,0,0,0-.068-.308A.683.683,0,0,0,10.786,2H9.351a7.518,7.518,0,1,0,.034,15.036h1.4a.683.683,0,0,0,.478-.205l.137-.171a.82.82,0,0,0,.068-.308A.683.683,0,0,0,10.786,15.669Z'
            transform='translate(-1.85 -2)'
            fill='#fff'
          />
        </g>
      </g>
    </svg>
  );
}

export default LogOut;
