import React from 'react';
import style from './ACA2024.module.scss';
import ACAChart2024Frame from './ACAChart2024Frame/ACAChart2024Frame.js';

function ACA2024() {
  return (
    <>
        <ACAChart2024Frame className={style.filesContainer} />
    </>
  );
}

export default ACA2024;
