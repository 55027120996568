import React from 'react';
import style from './tasksSection.module.scss';
import propTypes from 'prop-types';
import TaskCard from './TaskCard/TaskCard';
import { useEffect, useState } from 'react';
import ActionItemsFormModal from 'views/ActionItems/ActionItemsFormModal/ActionItemsFormModal';
import Card from 'components/Card/Card';
import { Link } from 'react-router-dom';
import Button from 'components/Button/Button';

function TasksSection({ tasks, loading }) {
  const [tasksToDisplay, setTasksToDisplay] = useState([]);
  const [modalState, setModalState] = useState({ active: false, id: '' });

  useEffect(() => {
    if (tasks) {
      let filteredTasks = tasks.filter(task => task.status === 'Open');
      setTasksToDisplay([...filteredTasks]);
    }
  }, [tasks]);

  return (
    <Card
      type='default'
      title={'Pending Tasks'}
      options={
        <Link to='/actionItems'>
          <Button size='xsmall' type='secondary' text={'View all'} />
        </Link>
      }
      fill
      loading={loading}
    >
      <div className={style.tasksSection}>
        <div className={style.statuses}>
          <div className={style.status}>
            <div></div>
            <span>Open</span>
          </div>
          <div className={style.status}>
            <div></div>
            <span>Expired</span>
          </div>
        </div>
        <div className={style.tasks}>
          {tasksToDisplay.map(task => (
            <TaskCard
              key={task.id}
              {...task}
              onClick={() => setModalState({ active: true, id: task.id })}
            />
          ))}
        </div>
      </div>
      {modalState.id && (
        <ActionItemsFormModal
          actionItemId={modalState.id}
          active={modalState.active}
          setModalState={setModalState}
        />
      )}
    </Card>
  );
}

TasksSection.propTypes = {
  tasks: propTypes.array,
  loading: propTypes.bool
};

export default TasksSection;
