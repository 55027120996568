import { combineReducers } from 'redux';
import authentication from './authentication';
import site from './site';
import dashboard from './dashboard';
import user from './user';
import appointments from './appointments';
import marketingAnnouncements from './marketingAnnouncements';
import commissions from './commissions';
import leads from './leads';
import bonus from './bonus';
import notifications from './notifications';
import agencyManagement from './agencyManagement';
import faqs from './faqs';

const reducers = combineReducers({
  authentication,
  site,
  dashboard,
  user,
  appointments,
  marketingAnnouncements,
  commissions,
  leads,
  bonus,
  notifications,
  agencyManagement,
  faqs
});

export default reducers;
