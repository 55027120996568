import InputWithCopyButton from 'components/InputWithCopyButton/InputWithCopyButton';
import React from 'react';
import { useState, useEffect } from 'react';
import FormWithEditButton from '../FormWithEditButton/FormWithEditButton';
import getStatesLicenseNumbers from './getStatesLicenseNumbers';
import style from './nonResidenceForm.module.scss';
import propTypes from 'prop-types';

function NonResidenceForm({ user = {}, onClick }) {
  const [licensedStates, setLicensedStates] = useState([]);

  useEffect(() => {
    setLicensedStates(getStatesLicenseNumbers(user));
  }, []);
 console.log(`Licenses : ${licensedStates}`);
 return (
  <div className={style.nonResidenceForm}>
    {licensedStates.map((state, i) => {
      const licenseKey = state === 'Texas' ? `${state}_License_Informatio` : `${state}_License_Number`;

      return (
        <InputWithCopyButton
          key={i}
          label={`${state}:`}
          value={user[licenseKey]}
          inLine
          lightLabel
          disabled
          noSpacing={true}
          fixedWidth={true}
        />
      );
    })}
  </div>
);
}

NonResidenceForm.propTypes = {
  user: propTypes.object,
  onClick: propTypes.func
};

export default NonResidenceForm;
