import { IS_LOGGING_IN, LOG_IN, LOG_OUT } from 'constants/types';

const INITIAL_STATE = {
  authenticated: false,
  isLoggingIn: false
};

function authenticationReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOG_IN:
      return { ...state, authenticated: true };
    case LOG_OUT:
      return INITIAL_STATE;
    case IS_LOGGING_IN:
      return { ...state, isLoggingIn: action.payload };
    default:
      return state;
  }
}

export default authenticationReducer;
