import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import FAQsCard from '../../components/FAQsCard/FAQsCard';
import FAQDetailView from '../../components/FAQsCard/FAQDetailCard';
import FAQDetailHidden from '../../components/FAQsCard/FAQDetailHidden';
import style from './faqsView.module.scss';

function FaqsView({ getFAQsData, setLocalLoader, faqs }) {
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [isMobileView, setIsMobileView] = useState(false);
  const cancelTokenSource = axios.CancelToken.source();

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    getFAQsData(setLocalLoader, cancelTokenSource);
    return () => {
      cancelTokenSource.cancel('API Call Canceled');
    };
  }, [getFAQsData, setLocalLoader]);

  const handleQuestionClick = (question) => {
    setSelectedQuestion(question);
    if (isMobileView) {
      document.getElementById('faqDetailView');
    }
  };

  const handleBackClick = () => {
    setSelectedQuestion(null);
  };

  return (
    <div className={style.container}>
      <div className={style.cardsWrapper}>
        {faqs.map((faq, index) => (
          <FAQsCard
            key={index}
            title={faq.type}
            questions={faq.questions}
            onClick={handleQuestionClick}
            selectedQuestion={selectedQuestion}
          />
        ))}
      </div>
      {(!isMobileView || selectedQuestion) && (
        <div className={style.detailView} id="faqDetailView">
          {selectedQuestion ? (
            <FAQDetailView
              question={selectedQuestion}
              onClose={() => setSelectedQuestion(null)}
              onBackClick={handleBackClick}
            />
          ) : (
            !isMobileView && <FAQDetailHidden />
          )}
        </div>
      )}
    </div>
  );
}

FaqsView.propTypes = {
  getFAQsData: PropTypes.func.isRequired,
  setLocalLoader: PropTypes.func.isRequired,
  faqs: PropTypes.array
};

export default FaqsView;
