import {
  Checkbox,
  TextField,
  FormControlLabel,
  FormGroup,
  Stack
} from '@mui/material';
import { Save } from '@mui/icons-material';
import Button from 'components/Button/Button';
import Card from 'components/Card/Card';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from '../AgencySettings.module.scss';
import PropTypes from 'prop-types';
import SignupAgents from './SignupAgents';

const CheckBoxItem = ({ checked, label, handleChangePreference }) => {
  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={handleChangePreference} />}
      label={label}
    />
  );
};

CheckBoxItem.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string,
  handleChangePreference: PropTypes.func
};

const CheckBoxItemMemoized = React.memo(
  CheckBoxItem,
  (prev, next) => prev.checked === next.checked
);

const INITIAL_VALUES = {
  Hide_ACA: false,
  Hide_ACA_Other: false,
  Hide_Group: false,
  Hide_Life: false,
  Hide_Annuity: false,
  Hide_Long: false,
  Hide_Disability: false,
  Hide_Medicare: false,
  Hide_OffMarket: false,
  Healthsherpa_Agency_Code: ''
};

const OnboardingFormManagement = ({ UpdateAgencyFormPreferences }) => {
  const agencyInformation = useSelector(
    state => state.agencyManagement.agencyData.agencyInformation
  );

  const agencyManagement = useSelector(state => state.agencyManagement);
  const zohoData = useSelector(state => state.user.zohoData);

  const [preferencesData, setPreferencesData] = useState(INITIAL_VALUES);
  // const [prevData, setPrevData] = useState(INITIAL_VALUES);
  const prevData = useRef(INITIAL_VALUES);

  useEffect(() => {
    const data = {
      Hide_ACA: agencyInformation.Hide_ACA,
      Hide_ACA_Other: agencyInformation.Hide_ACA_Other,
      Hide_Group: agencyInformation.Hide_Group,
      Hide_Life: agencyInformation.Hide_Life,
      Hide_Annuity: agencyInformation.Hide_Annuity,
      Hide_Long: agencyInformation.Hide_Long_term_Health,
      Hide_Disability: agencyInformation.Hide_Disability,
      Hide_Medicare: agencyInformation.Hide_Medicare,
      Hide_OffMarket: agencyInformation.Hide_Off_Market,
      Healthsherpa_Agency_Code: agencyInformation.Healthsherpa_Agency_Code
        ? agencyInformation.Healthsherpa_Agency_Code
        : '',
      Hide_O_Neill_Insurance_Email_Creation:
        agencyInformation.Hide_O_Neill_Insurance_Email_Creation
    };
    setPreferencesData(data);
    // setPrevData(data);
    prevData.current = data;
  }, [agencyInformation]);

  const [isSaveDisabled, setIsSaveDisabled] = useState(handleCheckPreferences);

  const handleChangePreference = (event, preference) => {
    setPreferencesData(prevData => {
      setIsSaveDisabled(
        handleCheckPreferences({
          ...prevData,
          [preference]: event.target.checked
        })
      );
      return { ...prevData, [preference]: event.target.checked };
    });
  };

  const handleChangeAgencyCodePreference = (event, preference) => {
    setPreferencesData(prevData => {
      setIsSaveDisabled(
        handleCheckPreferences({
          ...prevData,
          [preference]: event.target.value
        })
      );
      return { ...prevData, [preference]: event.target.value };
    });
  };

  const handleSavePreferences = async () => {
    await UpdateAgencyFormPreferences(preferencesData);
    // setPrevData(preferencesData);
    prevData.current = preferencesData;
    setIsSaveDisabled(true);
  };

  function handleCheckPreferences(object2 = preferencesData) {
    let diff = Object.keys(object2).reduce((diff, key) => {
      if (prevData.current[key] === object2[key]) return diff;
      return {
        ...diff,
        [key]: object2[key]
      };
    }, []);
    if (Array.isArray(diff)) {
      return true;
    } else {
      return false;
    }
  }
  return (
    <Card title='Sign-up Agents' titleType='primary'>
      <h4>Step 1: Form Preferences</h4>
      <p>
        (Optional) Select from the options below to hide categories of requested
        appointments from down the line agents at this agency.
      </p>
      <div className={styles.preferencesContainer}>
        <FormGroup className={styles.preferences}>
          <CheckBoxItemMemoized
            checked={preferencesData.Hide_ACA}
            label={'Hide ACA Major'}
            handleChangePreference={e => handleChangePreference(e, 'Hide_ACA')}
          />
          <CheckBoxItemMemoized
            checked={preferencesData.Hide_ACA_Other}
            label={'Hide ACA All others'}
            handleChangePreference={e =>
              handleChangePreference(e, 'Hide_ACA_Other')
            }
          />

          {/* <CheckBoxItemMemoized
            checked={preferencesData.Hide_Annuity}
            label={'Hide Annuity'}
            handleChangePreference={e =>
              handleChangePreference(e, 'Hide_Annuity')
            }
          /> */}
          {/* <CheckBoxItemMemoized
            checked={preferencesData.Hide_Long}
            label={'Hide Long Term Care'}
            handleChangePreference={e => handleChangePreference(e, 'Hide_Long')}
          /> */}
          {/* <CheckBoxItemMemoized
            checked={preferencesData.Hide_Disability}
            label={'Hide Disability'}
            handleChangePreference={e =>
              handleChangePreference(e, 'Hide_Disability')
            }
          /> */}
          <CheckBoxItemMemoized
            checked={preferencesData.Hide_Medicare}
            label={'Hide Medicare'}
            handleChangePreference={e =>
              handleChangePreference(e, 'Hide_Medicare')
            }
          />
          <CheckBoxItemMemoized
            checked={preferencesData.Hide_OffMarket}
            label={'Hide Off-Market'}
            handleChangePreference={e =>
              handleChangePreference(e, 'Hide_OffMarket')
            }
          />
          <CheckBoxItemMemoized
            checked={preferencesData.Hide_Group}
            label={'Hide Group'}
            handleChangePreference={e =>
              handleChangePreference(e, 'Hide_Group')
            }
          />
          <CheckBoxItemMemoized
            checked={preferencesData.Hide_Life}
            label={'Hide Life'}
            handleChangePreference={e => handleChangePreference(e, 'Hide_Life')}
          />
          <CheckBoxItemMemoized
            checked={Boolean(
              preferencesData.Hide_O_Neill_Insurance_Email_Creation
            )}
            label={"Hide O'Neill Email Creation"}
            handleChangePreference={e =>
              handleChangePreference(e, 'Hide_O_Neill_Insurance_Email_Creation')
            }
          />
        </FormGroup>
        <Stack>
          <p>HealthSherpa Agency Code</p>
          <TextField
            id='Healthsherpa_Agency_Code'
            label='Type HealthSherpa agency code'
            variant='outlined'
            value={preferencesData.Healthsherpa_Agency_Code}
            onChange={e =>
              handleChangeAgencyCodePreference(e, 'Healthsherpa_Agency_Code')
            }
          />
        </Stack>
        <br />
        <Button
          size='medium'
          text={'Save preferences'}
          type={'primaryDarkened'}
          rounded={'large'}
          icon={<Save />}
          onClick={handleSavePreferences}
          isLoading={agencyManagement.isUpdatingPreferences}
          disabled={
            isSaveDisabled || agencyManagement.isUpdatingPreferences
              ? true
              : false
          }
        />
        <br />
        <SignupAgents
          zohoData={zohoData}
          Healthsherpa_Agency_Code={preferencesData.Healthsherpa_Agency_Code}
        />
      </div>
    </Card>
  );
};

OnboardingFormManagement.propTypes = {
  SendAgencyFormEmail: PropTypes.func,
  UpdateAgencyFormPreferences: PropTypes.func
};

export default OnboardingFormManagement;
