import React from 'react';
import style from './input.module.scss';
import propTypes from 'prop-types';

function TextArea({ label, error, className, ...textareaProps }) {
  return (
    <div
      className={`${style.inputContainer} ${error && style.error} ${className}`}
    >
      <p>{label}</p>
      <textarea {...textareaProps} />
      <span>{error}</span>
    </div>
  );
}

TextArea.propTypes = {
  label: propTypes.string,
  error: propTypes.string,
  className: propTypes.string
};

export default TextArea;
