import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = () => {
  const authenticated = useSelector(
    state => state.authentication.authenticated
  );
  const zohoId = useSelector(state => state.user.zohoId);
  return authenticated && zohoId ? (
    <Outlet />
  ) : (
    <Navigate to={'/login'} replace />
  );
};

export default PrivateRoute;
