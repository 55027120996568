import React from 'react';
import MarketingCard from './MarketingCard/MarketingCard';
import style from './marketingView.module.scss';
import propTypes from 'prop-types';
import { useEffect } from 'react';
import { stringTrimmer } from 'helpers/strings';
import axios from 'axios';
import Card from 'components/Card/Card';

function MarketingView({
  announcements,
  getMarketingAnnouncements,
  setLocalLoader
}) {
  const cancelTokenSource = axios.CancelToken.source();

  useEffect(() => {
    getMarketingAnnouncements(setLocalLoader, cancelTokenSource);
    return () => {
      cancelTokenSource.cancel('API Call Canceled');
    };
  }, []);

  return (
    <Card title='Marketing' fill container>
      <div className={style.marketingView}>
        <div className={style.titles}>
          <h1>Our Services</h1>
          <h2>
            We provide multiple marketing services to help you increase your
            sales.
          </h2>
        </div>
        {announcements.length > 0 ? (
          <div className={style.cards}>
            {announcements.map((announcement, i) => (
              <MarketingCard
                key={`${announcement.title} ${i}`}
                title={announcement.title}
                description={stringTrimmer(announcement.description, 180)}
                url={announcement.url}
              />
            ))}
          </div>
        ) : (
          <p>No announcements available</p>
        )}
      </div>
    </Card>
  );
}

MarketingView.propTypes = {
  announcements: propTypes.array,
  getMarketingAnnouncements: propTypes.func,
  setLocalLoader: propTypes.func
};

export default MarketingView;
