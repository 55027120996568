import { AGENCY_DATA_INITIAL_STATE } from 'state/reducers/agencyManagement';

export default function (zohoData, firebaseData, agentPhoto, folders) {
  let zohoDataObj = userFactoryZohoData(zohoData, agentPhoto, folders);
  return {
    zohoData: zohoDataObj,
    firebaseData,
    zohoId: zohoData.id
  };
}

export function userFactoryZohoData(zohoData, agentPhoto, folders) {
  let remainingLeads =
    zohoData.Leads_Remaining_Health_Basic +
    zohoData.Leads_Remaining_Health_Premium +
    zohoData.Leads_Remaining_Health_Standard +
    zohoData.Leads_Remaining_Life_Premium +
    zohoData.Leads_Remaining_Life_Standard +
    zohoData.Leads_Remaining_Medicare_Premium +
    zohoData.Leads_Remaining_Medicare_Standard;
  return {
    ...zohoData,
    agencyData: zohoData.agencyData
      ? zohoData.agencyData
      : AGENCY_DATA_INITIAL_STATE,
    sharedFolder: folders[0],
    videoFolder: folders[1],
    agentPhoto,
    hasDistributionTier: zohoData.Distribution_Tier ? true : false,
    remainingLeads
  };
}
