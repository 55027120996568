import React from 'react';
import style from './CollapsibleList.module.scss';
import propTypes from 'prop-types';

const CollapsibleList = ({ options, children }) => {
  return (
    <ul className={style.collapsableList}>
      <div className={style.options}>{options}</div>
      {children}
    </ul>
  );
};

CollapsibleList.propTypes = {
  options: propTypes.element,
  children: propTypes.node
};

export default CollapsibleList;
