import React from 'react';

function Lead() {
  return (
    <svg viewBox='0 0 16 16.452'>
      <path
        d='M16.123,6.412a2.206,2.206,0,0,1,1.72-.636,2.842,2.842,0,0,1,1.944.823,2.622,2.622,0,0,1,.374,3.627.785.785,0,0,0,0,1.084.9.9,0,0,0,.523.187.673.673,0,0,0,.523-.224,3.878,3.878,0,0,0,.486-4.6h.262a2.281,2.281,0,0,0,1.683-.71,2.356,2.356,0,0,0-.075-3.328A2.43,2.43,0,0,0,21.956,2a2.468,2.468,0,0,0-1.72.71,2.356,2.356,0,0,0-.6,1.982,4.188,4.188,0,0,0-1.757-.411A3.552,3.552,0,0,0,15.039,5.4a.748.748,0,1,0,1.084,1.01Zm5.2-2.655a.9.9,0,0,1,.636-.262.835.835,0,0,1,.6,1.421.71.71,0,0,1-.6.262.86.86,0,0,1-.6-.224.823.823,0,0,1-.037-1.2Z'
        transform='translate(-10.216 -2)'
        fill='#9d9d9d'
      />
      <path
        d='M9.63,26.793A2.609,2.609,0,0,1,7.984,23.54a2.6,2.6,0,0,1,2.954-2.131.748.748,0,0,0,.9-.561.71.71,0,0,0-.523-.9,3.889,3.889,0,0,0-4.225,1.907,2.356,2.356,0,0,0-1.608-1.346l-.523-.075a2.356,2.356,0,0,0-2.281,1.795A2.318,2.318,0,0,0,4.4,25.073l.561.075a2.318,2.318,0,0,0,1.458-.523,3.926,3.926,0,0,0,2.879,3.627h.15a.748.748,0,0,0,.748-.561.71.71,0,0,0-.561-.9ZM5.778,22.979a.86.86,0,0,1-.823.673H4.732a.853.853,0,0,1,.224-1.683h.187a.785.785,0,0,1,.523.374A.935.935,0,0,1,5.778,22.979Z'
        transform='translate(-2.6 -13.183)'
        fill='#9d9d9d'
      />
      <path
        d='M29.554,25.772A4.449,4.449,0,0,0,30.788,24.5a3.664,3.664,0,0,0,.449-3.029.74.74,0,1,0-1.421.411,2.094,2.094,0,0,1-.3,1.795,3.066,3.066,0,0,1-1.683,1.271,2.607,2.607,0,0,1-3.328-1.5.758.758,0,1,0-1.458.411,3.926,3.926,0,0,0,3.776,2.692,2.356,2.356,0,0,0,1.87,3.739,2.131,2.131,0,0,0,.673-.112,2.356,2.356,0,0,0,.187-4.412Zm-.6,2.991h-.262a.9.9,0,0,1-.823-.561.935.935,0,0,1,.6-1.084h.224a.86.86,0,0,1,.823.636.823.823,0,0,1-.561,1.01Z'
        transform='translate(-15.384 -13.844)'
        fill='#9d9d9d'
      />
    </svg>
  );
}

export default Lead;
