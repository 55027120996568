import React from 'react';

function AgentInfo() {
  return (
    <svg viewBox='0 0 16 16'>
      <path
        d='M18.988,10a2.988,2.988,0,1,0,2.988,2.988A2.988,2.988,0,0,0,18.988,10Zm0,4.482a1.494,1.494,0,1,1,1.494-1.494A1.494,1.494,0,0,1,18.988,14.482Z'
        transform='translate(-10.988 -7.136)'
        fill='#9d9d9d'
      />
      <path
        d='M10,2a7.964,7.964,0,1,0,5.668,2.332A8,8,0,0,0,10,2ZM5.564,14.8A9.6,9.6,0,0,1,10,13.636,9.6,9.6,0,0,1,14.436,14.8a6.509,6.509,0,0,1-8.873,0Zm9.854-1.127h0A11.164,11.164,0,0,0,10,12.182a11.164,11.164,0,0,0-5.418,1.491h0A6.436,6.436,0,0,1,3.455,10a6.545,6.545,0,0,1,13.091,0,6.436,6.436,0,0,1-1.127,3.673Z'
        transform='translate(-2 -2)'
        fill='#9d9d9d'
      />
    </svg>
  );
}

export default AgentInfo;
