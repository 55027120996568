import validator from 'helpers/validator';

function validateNewPassword(data) {
  let errors = {};
  if (validator.isEmpty(data.currentPassword)) {
    errors.currentPassword = 'Please enter your current password';
  }
  if (validator.isEmpty(data.password)) {
    errors.password = 'Please enter a valid password';
  }
  if (validator.isEmpty(data.confirmPassword)) {
    errors.confirmPassword = 'Please confirm your password';
  }
  if (
    !errors?.password &&
    !errors?.confirmPassword &&
    data.password !== data.confirmPassword
  )
    errors.confirmPassword = "Passwords don't match";

  return { errors, isValid: validator.isEmpty(errors) };
}

export default validateNewPassword;
