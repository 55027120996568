import React from 'react';

function Bonus() {
  return (
    <svg viewBox='0 0 15.945 13.771'>
      <path
        d='M19.862,21.986c-1.268-.254-1.268-.471-1.268-.652a.362.362,0,0,1,.181-.326,1.087,1.087,0,0,1,.652-.145,2.138,2.138,0,0,1,1.16.326.652.652,0,0,0,.978-.181.689.689,0,0,0-.145-1.015,3.3,3.3,0,0,0-1.123-.435v-.833a.725.725,0,0,0-1.45,0v.8a1.948,1.948,0,0,0-1.776,1.884c0,.978,1.16,1.776,2.319,1.993s1.2.471,1.2.652-.362.507-.906.507h0a2.392,2.392,0,0,1-1.45-.471.761.761,0,0,0-1.051.217.689.689,0,0,0,.145.942,4.059,4.059,0,0,0,1.522.652v.8a.725.725,0,0,0,1.45,0v-.8a2.138,2.138,0,0,0,1.087-.471,1.776,1.776,0,0,0,.725-1.45h0C22.108,22.638,20.913,22.2,19.862,21.986Z'
        transform='translate(-11.599 -13.651)'
        fill='#959595'
      />
      <path
        d='M17.22,6H2.725A.725.725,0,0,0,2,6.725v11.6a.725.725,0,0,0,.725.725h2.9a.725.725,0,0,0,.725-.833.761.761,0,0,0-.761-.616H3.45V7.45H16.5V17.6H14.321a.725.725,0,0,0-.725.833.761.761,0,0,0,.761.616H17.22a.725.725,0,0,0,.725-.725V6.725A.725.725,0,0,0,17.22,6Z'
        transform='translate(-2 -6)'
        fill='#959595'
      />
      <circle
        cx='1'
        cy='1'
        r='1'
        transform='translate(2 2.092)'
        fill='#959595'
      />
      <circle
        cx='1'
        cy='1'
        r='1'
        transform='translate(4.455 2.092)'
        fill='#959595'
      />
      <circle
        cx='1'
        cy='1'
        r='1'
        transform='translate(7 2.092)'
        fill='#959595'
      />
    </svg>
  );
}

export default Bonus;
