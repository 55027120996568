import withLocalLoader from 'components/HOCs/withLocalLoader';
import { connect } from 'react-redux';
import AgencyManagementView from './AgencyManagementView';
import { getAgenciesTree } from 'state/actions/actions';

function mapStateToProps({ agencyManagement }) {
  return {
    agenciesTree: agencyManagement.agenciesTree
  };
}

export default connect(mapStateToProps, { getAgenciesTree })(
  withLocalLoader(AgencyManagementView, true, true, 'Agency Management')
);
