import React, { useEffect } from 'react';
import style from './login.module.scss';
import LoginForm from './LoginForm/LoginForm';
import propTypes from 'prop-types';
import LoginFormContainer from 'components/LoginFormContainer/LoginFormContainer';
import { Link } from 'react-router-dom';

function Login({ siteLoaded }) {
  useEffect(() => {
    siteLoaded();
  }, []);

  return (
    <LoginFormContainer>
      <LoginForm />
      <div className={style.links}>
        <div className={style.forgotPassword}>
          <Link to={'/resetPassword'}>Forgot your password?</Link>
        </div>
        <div>
          <span>{"Don't"} have an account?</span>
          <Link to={'/registration'}>Register</Link>
        </div>
      </div>
    </LoginFormContainer>
  );
}

Login.propTypes = {
  siteLoaded: propTypes.func
};

export default Login;
