import React, { useState } from 'react';
import style from './DropDownMenu.module.scss';
import PropTypes from 'prop-types';
import OutsideAlerter from 'hooks/useClickOutside';

const DropDownMenu = ({ menuTrigger, title, children }) => {
  const [active, setActive] = useState(false);
  // const preventRef = useRef(false);
  const handleToggleActive = () => {
    setActive(prev => !prev);
  };
  const handleClose = () => {
    setActive(false);
  };

  return (
    <OutsideAlerter onClick={handleClose}>
      <div className={`${style.menuContainer} ${active ? style.active : ''}`}>
        <div onClick={handleToggleActive}>{menuTrigger}</div>
        <div className={`${style.dropdownMenu}`}>
          {title && <div className={style.title}>{title}</div>}
          {children}
        </div>
      </div>
    </OutsideAlerter>
  );
};

DropDownMenu.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  menuTrigger: PropTypes.element
};

export default DropDownMenu;
