import React, { useEffect, useState } from 'react';
import style from './dashboard.module.scss';
import Carousel from 'components/Carousel/Carousel';
import ImportantDates from '../../components/ImportantDates/ImportantDates';
import getCompanyVariables from 'helpers/getCompanyVariables';
import Card from 'components/Card/Card';
import NotesSection from 'views/Dashboard/NotesSection/NotesSection';
import TasksSection from 'components/TasksSection/TasksSection';
import propTypes from 'prop-types';
import { getActiveAppointments } from 'helpers/dashboard/dashboard';
import Button from 'components/Button/Button';
import axios from 'axios';
import { Newspaper } from '@mui/icons-material';
import { Bookmarks } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import LensIcon from '@mui/icons-material/Lens';
import AppointmentCard from '../MyAppointments/AppointmentCard/AppointmentCard';
import OwnerCard from '../../components/AgentOwnerCard/OwnerCard';
import ActiveAppointments from '../../components/ActiveAppointmentsCard/ActiveAppointments';
import { useSelector } from 'react-redux';
import {
  getAppointmentStatus,
  getConfirmedAppointments,
} from 'helpers/appointments/appointments';


function Dashboard({
  announcements,
  notes,
  importantDates,
  owner,
  getDashboardData,
  localLoader,
  setLocalLoader,
  appointedCarriers,
  allCarriers,
  user
}) {
  const company = getCompanyVariables();
  const zohoData = useSelector(state => state.user.zohoData);
  const cardLoading = useSelector(state => state.appointments.loadingCard);
  const reqAppointments = useSelector(
    state => state.appointments.requestedAppointments
  );
  const generalOwnerZbookings = company === 'oneill' 
  ? `https://kerry-oneillmarketing1.zohobookings.com/#/customer/oneillmarketinginc?Name=${user.First_Name}%20${user.Last_Name}&Email=${user.Email}&Contact%20Number=+1${user.Phone}&NPN=${user.NPN_Text_Field}`
  : 'https://zohoadmin-sunshinelifehealth.zohobookings.com/#/4628285000000038008';

const carriers = [];
for (let key in allCarriers) {
  if (Object.keys(allCarriers[key]).length > 0) {
    for (let subKey in allCarriers[key]) {
      const carrierName = allCarriers[key][subKey].name;
      let MarktetplaceCarrier = true;
      if (appointedCarriers[key][carrierName]) {
        carriers.push({ carrierName,status:'Confirmed'});
        MarktetplaceCarrier = key === 'Marketplace' ? true : false;
      }
      if (reqAppointments[carrierName] && MarktetplaceCarrier) {
        carriers.push({ carrierName, status: 'Pending' });
      }
    }
  }
}
carriers.sort((a, b) => a.carrierName.localeCompare(b.carrierName));
  // const userState = useSelector(state => state.user);
  const cancelTokenSource = axios.CancelToken.source();
  useEffect(() => {
    getDashboardData(setLocalLoader, cancelTokenSource);
    return () => {
      cancelTokenSource.cancel('API Call Canceled');
    };
  }, []);

  const handleOpenUrl = url => {
    window.open(url, 'blank');
  };
  const navigate = useNavigate();


  return (
    <div className={style.dashboard}>
      <div className={style.carouselItem}>
        <Carousel data={announcements} timer={6000} />
      </div>
      {/*<div className={style.toolsItem}>
        <Card title='Tools' full titleOutside>
          <div className={style.buttons}>
             <Button
              text='Refer a Policy'
              size='medium'
              type='secondary'
              onClick={() =>
                handleOpenUrl(
                  'https://forms.oneillmarketing.net/oneillmarketing/form/ContactForm2/formperma/8tje3a2ccI2VpaIF2GoJPjd1sKsSVjPSO7srYcGJJZ8'
                )
              }
            /> 
            <Button
              text='Webinars'
              size='medium'
              type='secondary'
              onClick={() =>
                handleOpenUrl('https://oneillmarketing.net/webinars-roundups/')
              }
            />
            <Button
              text='Lead Sign-in'
              size='medium'
              type='secondary'
              onClick={() =>
                handleOpenUrl(
                  'https://leadportal.predictivecaller.com/users/login/'
                )
              }
            />
            <Button
              text='Lead Sign-up'
              size='medium'
              type='secondary'
              onClick={() =>
                handleOpenUrl(
                  `https://forms.zohopublic.com/oneillmarketing/form/LeadExchangeSignup2/formperma/dREQ-s30bPa4SnoB8l53pWrh-sFeD1uvglzt8AYSvVs?npn=${user.NPN_Text_Field}&firstName=${user.First_Name}&lastName=${user.Last_Name}`
                )
              }
            />
            </div>
        </Card>
      </div>*/}
      <div className={style.infoItem}>
        <div className={style.cardsItem}>
        <OwnerCard 
        owner={owner || {id:'',Name:'',Phone:'',Email:'',zohoBooking:'',imgURL:'',role:''} }   
        loading={localLoader}
        user={user || {}}
        generalBookingUrl={generalOwnerZbookings}
        />
            {/*</Card>div style={{ display: 'flex', gap: 10 }}>
              <a
                href='https://forms.zohopublic.com/oneillmarketing/form/ONeillMarketingTrainingRegistration/formperma/iCv0XfEiM1jXUw9juDngkju8D0f5UrTnsbQGFBlqHfY'
                target='_blank'
                rel='noreferrer'
             >     
              </a>
              <a
                href='https://trainingcentral.litmos.com/account/login/?'
                target='_blank'
                rel='noreferrer'
              >
                <Button size='small' text='Login Here' type='primaryLight' />
                </a>
            </div>*/}
 
        <ActiveAppointments
         carriers={carriers && Object.values(carriers)}
         loading={cardLoading}
        />
        <ImportantDates 
        dates={importantDates && Object.values(importantDates) }   
        loading={localLoader}
        />
        </div>
      </div>
      <div className={style.notesItem}>
        <NotesSection
          loading={localLoader}
          notes={notes && Object.values(notes)}
        />
      </div>
    </div>
  );
}

Dashboard.propTypes = {
  getDashboardData: propTypes.func,
  announcements: propTypes.array,
  importantDates:propTypes.array,
  owner:propTypes.object,
  notes: propTypes.object,
  appointedCarriers: propTypes.object,
  allCarriers:propTypes.object,
  user: propTypes.object,
  setLocalLoader: propTypes.func,
  localLoader: propTypes.bool
};

export default Dashboard;
