import withLocalLoader from 'components/HOCs/withLocalLoader';
import { connect } from 'react-redux';
import DashboardView from './DashboardView';
import { getDashboardData } from 'state/actions/actions';

function mapStateToProps({ user, dashboard, appointments }) {
  return {
    user: user.zohoData,
    announcements: dashboard.announcements,
    notes: dashboard.notes,
    importantDates: dashboard.importantDates,
    owner:dashboard.owner,
    appointedCarriers: appointments.appointedCarriers,
    allCarriers: appointments.carriers
  };
}

export default connect(mapStateToProps, { getDashboardData })(
  withLocalLoader(DashboardView, true, true, 'Home')
);
