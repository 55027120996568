import React, { useRef, useState, useEffect } from 'react'; 
import propTypes from 'prop-types';
import style from './appointmentCard.module.scss';
import {
  getAppointmentStatus,
  getConfirmedAppointments
} from 'helpers/appointments/appointments';
import { useSelector } from 'react-redux';
import { stringTrimmer } from 'helpers/strings';
import { stateAbbreviations } from 'constants/enum';
import LoadingContent from '../../../components/Loader/LoadingContante/LoadingContent';
import getCompanyVariables from 'helpers/getCompanyVariables';

function AppointmentCard({ active, setActive, name }) {
  const isSendingRequestAppointments = useSelector(
    state => state.appointments.isSendingRequestAppointments
  );
  const RequestedCarrierName = useSelector(
    state => state.appointments.requestedCarrierName
  );
  const zohoData = useSelector(state => state.user.zohoData);
  const reqAppointments = useSelector(
    state => state.appointments.requestedAppointments
  );

  const pendingStatesContainerRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const prevConfirmedRef = useRef();
  const prevPendingRef = useRef(); 
  let title = '';

  const company = getCompanyVariables();
  if (company === 'sunshine'){
     title = stringTrimmer(name, 15);
  }
  else if (company === 'oneill'){
     title = stringTrimmer(name, 19);
  }
  let confirmedResult = getConfirmedAppointments(name, zohoData);
  let confirmed = confirmedResult.length;
  let confirmedData = confirmedResult.data;
  let pendingStates = reqAppointments[name]
    ? (reqAppointments[name].carrier === 'BCBS'
        ? reqAppointments[name].bcbsState
        : reqAppointments[name].inProgressArray)
    : [];
  let pending = reqAppointments[name]
    ? (reqAppointments[name].carrier === 'BCBS'
        ? reqAppointments[name].bcbsState.length
        : reqAppointments[name].inProgressArray.length)
    : 0;
  let status;
  if (typeof confirmed === 'number')
    status = confirmed ? 'Confirmed' : pending ? 'Pending' : 'Not Requested';  
  else status = getAppointmentStatus(name, zohoData, reqAppointments);

  useEffect(() => {
    const prevConfirmed = prevConfirmedRef.current;
    const prevPending = prevPendingRef.current;

    if (isSendingRequestAppointments && RequestedCarrierName === name ) {
      setLoading(true);
    } else if (prevConfirmed !== undefined && prevPending !== undefined) {
      if (prevConfirmed !== confirmed || prevPending !== pending) {
        setLoading(false);
      }
    }

    prevConfirmedRef.current = confirmed;
    prevPendingRef.current = pending;
  }, [isSendingRequestAppointments, confirmed, pending]);

  return (
    <div
      className={`${style.appointmentCard} ${active === name && style.active} `}
      onClick={() => setActive(name)}
    >
      {loading ? (
        <LoadingContent /> // Muestra el loader mientras loading sea true
      ) : (
        <>
            <h2>{title}</h2>
            <hr className={style.hr} />
          <div
            className={`${style.content} ${
              typeof confirmed === 'number' ? style.withCounter : ''
            }`}
          >
            {typeof confirmed === 'number' ? (
              <>
                <div className={style.confirmedStates}>
                  {confirmedData.length > 0 ? (
                    confirmedData.map((state, index) => (
                      <span key={index} className={style.state}>
                        {index === confirmedData.length - 1 ? `${stateAbbreviations[state]}\u00A0` : `${stateAbbreviations[state]},\u00A0`}
                      </span>
                    ))
                  ) : null}
                </div>
                  <div className={style.pendingStates} ref={pendingStatesContainerRef}>
                    {pendingStates.length > 0 ? (
                      pendingStates.map((state, index) => (
                        <span key={index} className={style.state}>
                          {index === pendingStates.length - 1 ? `${stateAbbreviations[state]}\u00A0` : `${stateAbbreviations[state]},\u00A0`}
                        </span>
                      ))
                    ) : null}
                  </div>
              </>
            ) : (
              <span className={style.subtitle}>{status}</span>
            )}
          </div>
        </>
      )}
    </div>
  );
}

AppointmentCard.propTypes = {
  name: propTypes.string,
  active: propTypes.string,
  setActive: propTypes.func
};

export default AppointmentCard;

