import { CLEAN_LEADS, GET_LEADS, UPDATE_LEAD } from 'constants/types';

const INITIAL_STATE = {
  leads: {}
};

function leadsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_LEADS:
      return {
        ...state,
        leads: { ...state.leads, ...action.payload }
      };
    case UPDATE_LEAD:
      return {
        ...state,
        leads: {
          ...state.leads,
          [action.payload.leadId]: {
            ...state.leads[action.payload.leadId],
            note: action.payload.note,
            status: action.payload.status,
            modified: 'Today',
            responseTime: true,
            hasBeenClosed: action.payload.isClosed
          }
        }
      };
    case CLEAN_LEADS:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export default leadsReducer;
