import React from 'react';
import style from './changePasswordForm.module.scss';
import Input from 'components/Input/Input';
import { useState } from 'react';
import Button from 'components/Button/Button';
import ChangePasswordFormValidator from './ChangePasswordFormValidator';
import { useDispatch } from 'react-redux';
import { changeUserPassword } from 'state/actions/actions';
import { useNavigate } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';

function ChangePasswordForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formState, setFormState] = useState({
    password: '',
    confirmPassword: '',
    currentPassword: '',
    errors: {
      password: '',
      confirmPassword: '',
      currentPassword: ''
    }
  });

  const onInputChange = e => {
    let target = e.target;
    setFormState({
      ...formState,
      [target.name]: target.value,
      errors: { ...formState.errors, [target.name]: '' }
    });
  };

  const onSubmit = e => {
    e.preventDefault();
    const { errors, isValid } = ChangePasswordFormValidator(formState);
    if (!isValid) setFormState({ ...formState, errors });
    else {
      dispatch(
        changeUserPassword(
          formState.password,
          formState.currentPassword,
          {
            setFormState,
            formState
          },
          navigate
        )
      );
    }
  };

  return (
    <form className={style.genericForm}>
      <Input
        name='currentPassword'
        label='Current Password'
        type='password'
        error={formState.errors.currentPassword}
        placeholder='Enter your current password'
        onChange={onInputChange}
        value={formState.currentPassword}
        autoFocus
      />
      <Input
        name='password'
        label='Password'
        type='password'
        error={formState.errors.password}
        placeholder='Enter your new password'
        onChange={onInputChange}
        value={formState.password}
      />
      <Input
        name='confirmPassword'
        label='Confirm Password'
        type='password'
        error={formState.errors.confirmPassword}
        placeholder='Confirm Your Password'
        onChange={onInputChange}
        value={formState.confirmPassword}
      />
      <div className={style.buttons}>
        <Button
          text={'change password'}
          className={style.formButton}
          onClick={onSubmit}
        />
      </div>
    </form>
  );
}

export default ChangePasswordForm;
