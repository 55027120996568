// @flow
import { Menu } from '@mui/icons-material';
import React from 'react';
import style from './ToggleSideBar.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSidebar } from 'state/actions/site';

const ToggleSideBar = () => {
  const sidebarExpanded = useSelector(state => state.site.sidebarExpanded);
  const dispatch = useDispatch();
  return (
    <div
      title='Show/hide sidebar'
      className={`${style.toggleSideNavButton} toggleSideNavButton`}
      onClick={() => dispatch(toggleSidebar(!sidebarExpanded))}
    >
      <Menu />
    </div>
  );
};

export default ToggleSideBar;
