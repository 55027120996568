import LoginFormContainer from 'components/LoginFormContainer/LoginFormContainer';
import SecondaryPage from 'components/SecondaryPage/SecondaryPage';
import React from 'react';
import style from './resetPassword.module.scss';
import ResetPasswordForm from './ResetPasswordForm/ResetPasswordForm';

function ResetPassword() {
  return (
    <SecondaryPage>
      <LoginFormContainer>
        <h1 className={style.title}>Recover your password</h1>
        <ResetPasswordForm />
      </LoginFormContainer>
    </SecondaryPage>
  );
}

export default ResetPassword;
