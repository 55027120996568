import endpoints from 'constants/endpoints';
import {
  GET_USER,
  LOG_IN,
  REFRESH_USER,
  SHOW_ALERT,
  SET_VIEWING_AS,
  SET_USER_DATA,
  RESET_VIEWING_AS,
  IS_SENDING_INFORMATION
} from 'constants/types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { siteLoaded } from './actions';
import { safePostCall, postApiCall } from 'helpers/firebaseSetup';
import { showAlert, showAlertModal, siteLoading } from './site';
import userFactory, { userFactoryZohoData } from 'helpers/factory/userFactory';
import getCompanyVariables from 'helpers/getCompanyVariables';
import CompanyName from '../../constants/companyNames';

const company = getCompanyVariables();

let companyFullName = ''; 

if (company === 'sunshine') {
  companyFullName = CompanyName.fullName;
} else {
  companyFullName = CompanyName.fullName; 
}


export const setViewingAsAgent = agentID => async dispatch => {
  dispatch({ type: SET_VIEWING_AS, payload: agentID });
};

export const resetViewingAsAgent = zohoId => async dispatch => {
  dispatch({ type: RESET_VIEWING_AS, payload: zohoId });
};

export const setIsSendingInformation = payload => async dispatch => {
  dispatch({ type: IS_SENDING_INFORMATION, payload });
};

export const getUser = (firebaseData, userData, setUserData) => {
  return async dispatch => {
    safePostCall(endpoints.awsProxy.safePost, {
      googleId: firebaseData.user.uid,
      zohoId: false,
      endpoint: endpoints.zoho.getAgent
    })
      .then(async zohoRes => {
        console.log('zohoRes');
        console.log(zohoRes);
        const zohoData = JSON.parse(zohoRes.data.details.output);

        let folders = await getSharedFolder(zohoData.id);
        let agentPhoto = folders[2] === 'null' ? '' : folders[2];
        dispatch({
          type: SET_USER_DATA,
          payload: {
            data: userFactory(zohoData, firebaseData, agentPhoto, folders),
            mainZohoId: zohoData.id
          }
        });
        dispatch({ type: LOG_IN });
        location.href = '/';
      })
      .catch(() => {
        setUserData({
          ...userData,
          errors: {
            ...userData.errors,
            email: 'User not found'
          }
        });
        dispatch(siteLoaded());
      });
  };
};

async function getSharedFolder(zohoId) {
  return safePostCall(endpoints.awsProxy.safePost, {
    endpoint: endpoints.zoho.getSharedFoldersAndUserPhoto,
    agentId: zohoId
  }).then(response => {
    return response.data.details.output.split(',');
  });
}

export const refreshUser =
  (finishedRefresh = () => {}, cancelTokenSource = {}) =>
  async (dispatch, getState) => {
    let isViewingAsAgent = getState().user.viewAsAgent;
    let firebaseData = getState().user.firebaseData;

    let zohoId = '';
    if (isViewingAsAgent) {
      zohoId = isViewingAsAgent;
    } else {
      zohoId = getState().user.zohoId;
    }

    let folders = await getSharedFolder(zohoId);
    let agentPhoto = folders[2] === 'null' ? '' : folders[2];
    safePostCall(
      endpoints.awsProxy.safePost,
      {
        zohoId,
        endpoint: endpoints.zoho.getAgent
      },
      {
        cancelToken: cancelTokenSource.token
      }
    )
      .then(zohoRes => {
        zohoRes = JSON.parse(zohoRes.data.details.output);

        if (isViewingAsAgent) {
          dispatch({
            type: GET_USER,
            payload: userFactory(zohoRes, firebaseData, agentPhoto, folders)
          });
        } else {
          dispatch({
            type: REFRESH_USER,
            payload: userFactoryZohoData(zohoRes, agentPhoto, folders)
          });
        }
        finishedRefresh();
        return zohoRes;
      })
      .catch(error => {
        console.log(error);
        if (!(error.message === 'API Call Canceled')) finishedRefresh();
      });
  };

export const changeUserPassword =
  (password, currentPassword, form, navigate) => (dispatch, getState) => {
    const { setFormState, formState } = form;
    const zohoData = getState().user.zohoData;
    const user = firebase.auth().currentUser;

    dispatch(siteLoading());

    const credential = firebase.auth.EmailAuthProvider.credential(
      user.email,
      currentPassword
    );

    user
      .reauthenticateWithCredential(credential)
      .then(() => {
        user
          .updatePassword(password)
          .then(() => {
            dispatch({
              type: REFRESH_USER,
              payload: { ...zohoData, Portal_Password: password }
            });

            dispatch(siteLoaded());

            dispatch({
              type: SHOW_ALERT,
              payload: {
                message: 'Password Updated Successfully',
                code: 'success'
              }
            });
            navigate(-1);
          })
          .catch(error => {
            console.log(error);
            dispatch(siteLoaded());
            dispatch({
              type: SHOW_ALERT,
              payload: {
                message: "There's been an error",
                code: 'error'
              }
            });
          });
      })
      .catch(() => {
        setFormState({
          ...formState,
          errors: { ...formState.errors, currentPassword: 'Wrong password' }
        });
        dispatch({
          type: SHOW_ALERT,
          payload: {
            message: "There's been an error",
            code: 'error'
          }
        });
        dispatch(siteLoaded());
      });
  };

export const sendEmailModifyAgentData = () => (dispatch, getState) => {
  let zohoId = getState().user.zohoId;
  dispatch(showAlert({ message: 'Sending Email', code: 'info' }));
  safePostCall(endpoints.awsProxy.safePost, {
    endpoint: endpoints.zoho.sendEmailsAgentInfo,
    agentId: zohoId
  })
    .then(() => {
      dispatch(
        showAlert({ message: 'Email sent successfully!', code: 'success' })
      );
    })
    .catch(error => console.log(error));
};

export const sendEmailPurchaseMoreLeads = () => (dispatch, getState) => {
  let zohoId = getState().user.zohoId;
  dispatch(showAlert({ message: 'Sending Email', code: 'info' }));
  safePostCall(endpoints.awsProxy.safePost, {
    endpoint: endpoints.zoho.sendPurchaseMoreLeadsForm,
    agentId: zohoId
  })
    .then(() => {
      dispatch(
        showAlert({ message: 'Email sent successfully!', code: 'success' })
      );
    })
    .catch(error => console.log(error));
};

export const resetPasswordByEmail =
  (email, setFormState, initialState) => dispatch => {
    dispatch(showAlert({ message: 'Sending Email', code: 'info' }));
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        dispatch(
          showAlert({ message: 'Email Sent Successfully!', code: 'success' })
        );
        setFormState(initialState);
      })
      .catch(error => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log({ errorCode, errorMessage });
        dispatch(
          showAlert({
            message: "There's been an error. Try Again",
            code: 'error'
          })
        );
      });
  };

export const askForAUserRegistration =
  (email, userName, navigate) => dispatch => {
    dispatch(setIsSendingInformation(true));
    dispatch(
      showAlert({
        message: 'Sending your info to owr team',
        code: 'info'
      })
    );
    postApiCall(endpoints.awsProxy.genericPost, {
      endpoint:
        'https://www.zohoapis.com/crm/v2/functions/portal_access_request/actions/execute?auth_type=apikey&zapikey=1003.4bb618f595dfca087f0d3f510d076ca3.78f38d5911bba445b80aea9f7dc2b34f',
      agentEmail: email,
      agentName: userName
    })
      .then(() => {
        dispatch(setIsSendingInformation(false));
        dispatch(
          showAlertModal({
            title: 'Request sent successfully!',
            message: `Your request to join the ${companyName} Portal has been submitted. You will be contacted shortly to your email (${email}) for next steps.`,
            size: 'small',
            code: 'success',
            buttonText: 'Ok'
          })
        );
        dispatch(
          showAlert({ message: 'Request sent successfully!', code: 'success' })
        );
        navigate(-1);
      })
      .catch(error => {
        console.log(error);
        dispatch(setIsSendingInformation(false));
        dispatch(
          showAlert({
            message:
              'An error ocurred, please try again. If the problem persists contact support',
            code: 'error'
          })
        );
      });
  };

export const changeUserProfilePic =
  (base64Image, agentId, fileInputRef, setLocalLoader, cancelTokenSource) =>
  dispatch => {
    safePostCall(
      endpoints.awsProxy.safePost,
      {
        endpoint: endpoints.zoho.uploadProfilePic,
        agentId,
        content: base64Image
      },
      { headers: { Accept: 'application/json' }, timeout: 5000 }
    )
      .then(() => {
        dispatch(
          refreshUser(() => {
            setLocalLoader(false);
            dispatch(
              showAlert({ message: 'Profile Photo Updated', code: 'success' })
            );
          }, cancelTokenSource)
        );

        fileInputRef.current.value = null;
      })
      .catch(error => {
        let message = "Couldn't Update Profile Photo";
        fileInputRef.current.value = null;

        console.log(error);

        if (JSON.stringify(error).includes('timeout'))
          message = `${message}. File might be too large`;

        dispatch(
          showAlert({
            message,
            code: 'error'
          })
        );
      });
  };
