import React,{useRef, useState,useEffect} from 'react';
import style from './CardNoStates.module.scss';
import propTypes from 'prop-types';
import {
  getAppointmentStatus,
  getConfirmedAppointments
} from 'helpers/appointments/appointments';
import { useSelector } from 'react-redux';
import { stringTrimmer } from 'helpers/strings';
import LoadingContent from '../../../components/Loader/LoadingContante/LoadingContent';
import getCompanyVariables from 'helpers/getCompanyVariables';

function AppointmentCardNoStates({ active, setActive, name }) {
  const isSendingRequestAppointments = useSelector(
    state => state.appointments.isSendingRequestAppointments
  );
  const RequestedCarrierName = useSelector(
    state => state.appointments.requestedCarrierName
  );
  const zohoData = useSelector(state => state.user.zohoData);
  const reqAppointments = useSelector(
    state => state.appointments.requestedAppointments
  );
  const [loading, setLoading] = useState(false);
  const prevConfirmedRef = useRef();
  const prevPendingRef = useRef(); 

  const company = getCompanyVariables();
  let title = '';
  if (company === 'sunshine'){
     title = stringTrimmer(name, 18);
  }
  else if (company === 'oneill'){
     title = stringTrimmer(name, 17);
  }

  let confirmed = getConfirmedAppointments(name, zohoData);
  let pending = reqAppointments[name]
    ? reqAppointments[name].inProgressArray.length
    : 0;
  let status;

  if (typeof confirmed === 'number')
    status = confirmed ? 'Confirmed' : pending ? 'Pending' : 'Not Requested';
  else status = getAppointmentStatus(name, zohoData, reqAppointments);

  useEffect(() => {
    const prevConfirmed = prevConfirmedRef.current;
    const prevPending = prevPendingRef.current;

    if (isSendingRequestAppointments && RequestedCarrierName === name ) {
      setLoading(true);
    } else if (prevConfirmed !== undefined && prevPending !== undefined) {
      if (prevConfirmed !== confirmed || prevPending !== pending) {
        setLoading(false);
      }
    }

    prevConfirmedRef.current = confirmed;
    prevPendingRef.current = pending;
  }, [isSendingRequestAppointments, confirmed, pending]);

  return (
    <div
      className={`${style.appointmentCard} ${statusStyle[status]} ${
        active === name && style.active
      } `}
      onClick={() => setActive(name)}
    >
      {loading ? (
        <LoadingContent /> // Muestra el loader mientras loading sea true
      ) : (
        <>
          <h2>{title}</h2>
          <hr className={style.hr} />
            <div className={typeof confirmed === 'number' ? style.withCounter : null}>
              {typeof confirmed === 'number' ? (
                <>
                  <div className={style.counter}>
                    <span className={style.number}>{confirmed}</span>
                    <span>Confirmed</span>
                  </div>
                  <div className={style.counter}>
                    <span className={style.number}>{pending}</span>
                    <span>Pending</span>
                  </div>
                </>
            ) : (
              <span className={style.subtitle}>{status}</span>
            )}
          </div>
      </>
      )}
    </div>
  );
}

AppointmentCardNoStates.propTypes = {
  name: propTypes.string,
  active: propTypes.string,
  setActive: propTypes.func
};

export default AppointmentCardNoStates;

const statusStyle = {
  Confirmed: style.confirmed,
  Pending: style.pending,
  'Not Requested': style.notRequested
};
