import store from 'state/store/store';
import { setViewingAsAgent, resetViewingAsAgent } from 'state/actions/actions';

export default function enableViewingAsAgent() {
  const params = new URLSearchParams(window.location.search);
  if (params.has('va')) {
    const agentID = params.get('va');
    store.dispatch(setViewingAsAgent(agentID));
  } else {
    const mainZohoId = store.getState().user.mainZohoId;
    store.dispatch(resetViewingAsAgent(mainZohoId));
  }
}
