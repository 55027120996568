import React, { useEffect, useRef, useState } from 'react';
import Close from 'components/svg/icons/Close';
import style from './notificationBar.module.scss';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';

function NotificationBar({ currentPage, subPage = '' }) {
  const notifications = useSelector(state => state.notifications.notifications);
  const [active, setActive] = useState(true);

  let page;

  subPage ? (page = subPage) : (page = currentPage);

  let notification = notifications[page] ? notifications[page] : {};
  const timer = useRef(null);

  useEffect(() => {
    clearTimeout(timer.current);
    if (active && notification) {
      timer.current = setTimeout(() => {
        setActive(false);
      }, 5000);
    }
  }, [active]);

  return (
    <>
      {notification.notification && (
        <div
          className={`${style.notificationBar} ${
            active ? style.active : null
          } `}
        >
          <span>{notification.notification}</span>
          <div className={style.close} onClick={() => setActive(false)}>
            <Close />
          </div>
        </div>
      )}
    </>
  );
}

NotificationBar.propTypes = {
  currentPage: propTypes.string,
  subPage: propTypes.string
};

export default NotificationBar;
