import React from 'react';
import style from './OMOffering.module.scss';
import OMFrame from './OMFrame/OMFrame';

function OMOfferings() {
  return (
    <>
        <OMFrame/>
    </>
  );
}

export default OMOfferings;
