import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import style from './sideSection.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Stack, TextField } from '@mui/material';
import Button from 'components/Button/Button';
import AutocompleteInput from 'components/Autocomplete/AutocompleteInput';
import { STATE_LIST_CERT, TRANSFER_OPTIONS } from 'constants/enum';
import { arrayDifference } from 'helpers/arrays';
import axios from 'axios';
import StateBasedExchangeRequestModals from '../StateBasedExchangeRequestModals/StateBasedExchangeRequestModals';
import { showAlertModal } from 'state/actions/site';
import companyName from 'constants/companyNames';
import { setRequestedCarrierName } from '../../../state/actions/appointments';

const INITIAL_VALUES = {
  appointments: [],
  appointedType: 'New Appointment'
};

function SideSectionUnnapointed({
  confirmed = [],
  pending = [],
  carrier,
  statesOffered,
  isMarketplace,
  carrierData,
  addRequestedAppointments,
  InitialOnboardingFormCompleted
}) {
  const cancelTokenSource = axios.CancelToken.source();
  // Redux
  const isSendingRequestAppointments = useSelector(
    state => state.appointments.isSendingRequestAppointments
  );

  const dispatch = useDispatch();
  // Local
  const [formData, setFormData] = useState(INITIAL_VALUES);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);

  const handleChangeAppointments = values => {
    setFormData(prevState => ({ ...prevState, appointments: values }));
  };

  const handleChangeAppointedType = e => {
    setFormData(prevState => ({ ...prevState, appointedType: e.target.value }));
  };

  const handleConfirmRequest = () => {
    // if InitialOnboardingFormCompleted
    if (InitialOnboardingFormCompleted) {
      // check if state requires certification
      const isRequiredStateIncluded = STATE_LIST_CERT.some(state =>
        formData.appointments.includes(state.state)
      );
      if (isRequiredStateIncluded) {
        setOpenConfirmationModal(true);
      } else {
        handleSendRequest(formData.appointments);
      }
    } else {
      dispatch(
        showAlertModal({
          title: 'Initial Onboarding Form Not Completed',
          message: `You must complete the Onboarding Form that was emailed to you and have it approved in order to be able to request appointments. Please search your inbox for the email with the subject: “O${"'"}Neill Marketing Onboarding Steps”, or reach out to us for assistance at Contracting@Oneillmarketing.net.`
        })
      );
    }
  };

  const handleSendNotRequiredStates = nonRequiredStates => {
    handleSendRequest(nonRequiredStates);
  };

  const handleSendRequest = async appointments => {
    setOpenConfirmationModal(false);
    setOpenInfoModal(false);
    const hasStates = appointments.length > 0 ? true : false;
    const data = {
      hasStates: !isMarketplace ? false : hasStates,
      carrierName: carrier,
      states: appointments,
      transfer: formData.appointedType,
      carrierData
    };
    dispatch(setRequestedCarrierName(carrier));
    await addRequestedAppointments(cancelTokenSource, data);
    setFormData(INITIAL_VALUES);
  };

  useEffect(() => {
    setFormData(INITIAL_VALUES);
  }, [carrier]);

  return (
    <div className={style.sideSection}>
      <div className={style.section}>
        <center>
          <p className={style.title}>Not requested</p>
        </center>
      </div>
      <div className={style.section}>
        <p className={style.title}>Request new state appointments</p>
        {isMarketplace && (
          <>
            <p className={style.subtitle}>
              Select states you want to request, then click {'"Send request"'}{' '}
              to request your appointments.
            </p>
            <Stack spacing={5} sx={{ width: '100%', mb: 3, mt: 2 }}>
              <AutocompleteInput
                multiple
                value={formData.appointments}
                label='Select or search states'
                placeholder='Search'
                options={arrayDifference(statesOffered, [
                  ...confirmed,
                  ...pending
                ])}
                onValueChanges={handleChangeAppointments}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={'Select or search states'}
                    placeholder={'Select or search states'}
                    error={formData.appointments.length === 0}
                    helperText={
                      formData.appointments.length === 0
                        ? 'You need to select at least 1 state'
                        : ''
                    }
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
              />
            </Stack>
          </>
        )}
        <p className={style.subtitle}>
          Only if you need to transfer your appointment, please tell us how you are
          currently appointed:
        </p>
        <Stack spacing={5} sx={{ width: '100%', mb: 3, mt: 2 }}>
          <AutocompleteInput
            disableSearch
            value={formData.appointedType}
            label='Select transfer type (only if applicable)'
            placeholder='Select transfer type (only if applicable)'
            options={TRANSFER_OPTIONS}
            onValueChanges={handleChangeAppointedType}
            helperText={`***Only select if you already have a carrier appointment but need to
            move it to ${companyName.fullName}.`}
          />
        </Stack>
        <Button
          disabled={
            (isMarketplace && formData.appointments.length < 1) ||
            isSendingRequestAppointments
              ? true
              : false
          }
          isLoading={isSendingRequestAppointments}
          size='medium'
          type='primaryLight'
          text={'Send request'}
          rounded={'large'}
          fill
          onClick={handleConfirmRequest}
        />
      </div>
      <StateBasedExchangeRequestModals
        appointments={formData.appointments}
        openConfirmationModal={openConfirmationModal}
        openInfoModal={openInfoModal}
        setOpenConfirmationModal={setOpenConfirmationModal}
        setOpenInfoModal={setOpenInfoModal}
        onSendRequest={handleSendRequest}
        onSendNotRequiredStates={handleSendNotRequiredStates}
      />
    </div>
  );
}

SideSectionUnnapointed.propTypes = {
  carrier: propTypes.string,
  confirmed: propTypes.array,
  pending: propTypes.array,
  statesOffered: propTypes.array,
  isMarketplace: propTypes.bool,
  carrierData: propTypes.object,
  addRequestedAppointments: propTypes.func,
  InitialOnboardingFormCompleted: propTypes.bool
};

export default SideSectionUnnapointed;
