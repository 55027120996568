import React from 'react';

function Plus() {
  return (
    <svg viewBox='0 0 17.267 17.267'>
      <path
        d='M23.251,14.618h-6.6v-6.6a1.016,1.016,0,0,0-2.031,0v6.6h-6.6a1.016,1.016,0,0,0,0,2.031h6.6v6.6a1.016,1.016,0,1,0,2.031,0v-6.6h6.6a1.016,1.016,0,1,0,0-2.031Z'
        transform='translate(-7 -7)'
        fill='#39aafb'
      />
    </svg>
  );
}

export default Plus;
