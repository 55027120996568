import React, { useState } from 'react';
import Input from 'components/Input/Input';
import style from './resetPasswordForm.module.scss';
import Button from 'components/Button/Button';
import { useDispatch } from 'react-redux';
import { resetPasswordByEmail } from 'state/actions/actions';
import ResetPasswordFormValidator from './ResetPasswordFormValidator';

function ResetPasswordForm() {
  const dispatch = useDispatch();
  const [formState, setFormState] = useState({
    email: '',
    errors: {
      email: ''
    }
  });

  const onInputChange = e => {
    let target = e.target;
    setFormState({
      ...formState,
      [target.name]: target.value,
      errors: { ...formState.errors, [target.name]: '' }
    });
  };

  const resetPasswordEmail = e => {
    e.preventDefault();
    const { errors, isValid } = ResetPasswordFormValidator(formState);
    if (!isValid) setFormState({ ...formState, errors });
    else {
      dispatch(
        resetPasswordByEmail(formState.email, setFormState, {
          email: '',
          errors: {
            email: ''
          }
        })
      );
    }
  };

  return (
    <form className={style.genericForm}>
      <p className={style.notice}>
        We&apos;ll send an email with a link for you to reset your password.
        Please enter the email address with which you are registered.
      </p>
      <Input
        name='email'
        label='Email'
        type='email'
        error={formState.errors.email}
        placeholder='Enter your email address'
        onChange={onInputChange}
        value={formState.email}
        autoFocus
      />

      <Button fill text='Send Email' onClick={resetPasswordEmail} />
    </form>
  );
}

export default ResetPasswordForm;
