import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import companyName from './constants/companyNames';
const link = document.querySelector("link[rel~='icon']");
switch (companyName.short) {
  case 'O\'Neill':
    document.title = 'O\'Neill Portal';
    link.href = '/favicon.ico';
    break;
  case 'Sunshine':
    document.title = 'Sunshine Portal';
    link.href = '/faviconSunshine.ico';
    break;
}
ReactDOM.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
  document.getElementById('root')
);
