import { Edit } from '@mui/icons-material';
import Button from 'components/Button/Button';
import Card from 'components/Card/Card';
import Modal from 'components/Modal/Modal';
import SendEmailConfirmationForm from 'components/SendEmailConfirmationForm/SendEmailConfirmationForm';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from '../AgencySettings.module.scss';
import bankAccountFactory from 'helpers/factory/bankAccountFactory';

const AgencyBankInformation = ({
  agencyInformation,
  SendEditAgencyInfoFormToAgent
}) => {
  const [modalState, setModalState] = useState(false);
  let accountNumber = agencyInformation.Agency_Account_Number
    ? bankAccountFactory(agencyInformation.Agency_Account_Number)
    : '---';
  return (
    <React.Fragment>
      <Card
        title='Agency Banking Information'
        titleType='primary'
        options={
          <Button
            type='primary'
            size='medium'
            text={'Edit'}
            icon={<Edit />}
            onClick={() => setModalState(true)}
          />
        }
      >
        <div className={styles.agencyInformation}>
          <div>
            <p>Bank Name</p>
            <span>
              {agencyInformation.Name_of_Financial_Institution
                ? agencyInformation.Name_of_Financial_Institution
                : '---'}
            </span>
          </div>

          <div>
            <p>Routing Number Type</p>
            <span>
              {agencyInformation.Agency_Routing_Number_Type
                ? agencyInformation.Agency_Routing_Number_Type
                : '---'}
            </span>
          </div>
          <div>
            <p>Routing Number</p>
            <span>
            {agencyInformation.Agency_Routing_Number
                ? agencyInformation.Agency_Routing_Number
                : '---'}
            </span>
          </div>
          <div>
            <p>Account Number </p>
            <span>
            {accountNumber}
            </span>
          </div>
        </div>
      </Card>
      <Modal
        active={modalState}
        size={'auto'}
        closeModal={() => setModalState(false)}
      >
        <SendEmailConfirmationForm
          closeModal={() => setModalState(false)}
          modalText={
            'We will send you an email with links to securely update agency Banking information. Please click "Send" below.'
          }
          dispatchFunction={SendEditAgencyInfoFormToAgent}
        />
      </Modal>
    </React.Fragment>
  );
};
AgencyBankInformation.propTypes = {
  agencyInformation: PropTypes.object,
  SendEditAgencyInfoFormToAgent: PropTypes.func
};

export default AgencyBankInformation;
