import endpoints from 'constants/endpoints';
import { UPDATE_ACTION_ITEM } from 'constants/types';
import { safePostCall } from 'helpers/firebaseSetup';
import { siteLoaded, siteLoading } from './site';

export const updateActionItem = (closeModal, actionItem) => dispatch => {
  dispatch(siteLoading());
  safePostCall(endpoints.awsProxy.safePost, {
    ...actionItem,
    create: false,
    endpoint: endpoints.zoho.updateActionItem
  })
    .then(() => {
      //Refresh redux state with the updated note
      dispatch({ type: UPDATE_ACTION_ITEM, payload: actionItem });
      closeModal();
      dispatch(siteLoaded());
    })
    .catch(error => {
      console.log(error);
      dispatch(siteLoaded());
    });
};
