import React from 'react';
import style from './files.module.scss';
import { useParams } from 'react-router-dom';
import propTypes from 'prop-types';
import PersonalFolder from './PersonalFoler/PersonalFolder';
import SharedFolder from './SharedFolder/SharedFolder';

function Files() {
  const { type } = useParams();

  return (
    <>
      {type === 'Personal' ? (
        <PersonalFolder className={style.filesContainer} />
      ) : (
        <SharedFolder className={style.filesContainer} />
      )}
    </>
  );
}

Files.propTypes = {
  agentFolder: propTypes.string,
  sharedFolder: propTypes.string
};

export default Files;
