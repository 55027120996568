import withLocalLoader from 'components/HOCs/withLocalLoader';
import { connect } from 'react-redux';
import Leads from './Leads';
import { getLeads } from 'state/actions/actions';

const mapStateToProps = ({ leads, user }) => ({
  leads: leads.leads,
  hasDistributionTier: user.zohoData.hasDistributionTier
});

export default connect(mapStateToProps, { getLeads })(
  withLocalLoader(Leads, false, true, 'Leads')
);
