import React, { useState } from 'react';
import MyAppointmentsSection from 'views/MyAppointments/MyAppointmentsSection/MyAppointmentsSection';
import style from './mainSectionCarriers.module.scss';
import propTypes from 'prop-types';
import CollapsibleList from 'components/CollapsibleList/CollapsibleList';
import CollapsibleSection from 'components/CollapsibleList/CollapsibleSection';
import Button from 'components/Button/Button';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import LoadingContent from 'components/Loader/LoadingContante/LoadingContent';
import { useSelector } from 'react-redux';

function MainSectionCarriers({
  carriers,
  active,
  setActive = () => {},
  Card,
  CardNoStates,
  header,
  message = 'No Appointed Carriers',
  localLoader
}) {
  const marketplaceSortedKeys = Object.keys(carriers.Marketplace).sort();
  const offMarketSortedKeys = Object.keys(carriers['Off-Market Health']).sort();
  const medicareSortedKeys = Object.keys(carriers.Medicare).sort();
  const lifeSortedKeys = Object.keys(carriers.Life).sort();
  const groupSortedKeys = Object.keys(carriers.Group).sort();

  const [expandAll, setExpandAll] = useState(false);
  const zohoData = useSelector(state => state.user.zohoData);

  return (
    <div className={style.MainSectionCarriers}>
      {Object.values(carriers.Marketplace).length ||
      Object.values(carriers['Off-Market Health']).length ||
      Object.values(carriers.Medicare).length ||
      Object.values(carriers.Life).length ||
      Object.values(carriers.Group).length ? (
        <CollapsibleList
          options={
            <Button
              outlined
              size='xsmall'
              text={expandAll ? 'Collapse all' : 'Expand all'}
              type={'default'}
              icon={expandAll ? <ExpandLess /> : <ExpandMore />}
              onClick={() => setExpandAll(!expandAll)}
            />
          }
        >
          {header}
          <CollapsibleSection
            title='Marketplace Appointments'
            id='MarketplaceAppointments'
            subtitle='Affordable Care Act Carriers.'
            arrowRight
            expanded={true}
          >
            {Object.values(carriers.Marketplace).length ? (
              <MyAppointmentsSection>
                {marketplaceSortedKeys.map((key, i) => {
                  let carrier = carriers.Marketplace[key];
                  return (
                    <Card
                      key={`${carrier.name} ${i}`}
                      {...carrier}
                      active={active}
                      setActive={setActive}
                    />
                  );
                })}
              </MyAppointmentsSection>
            ) : (
              'No marketplace appointments available'
            )}
          </CollapsibleSection>

          {zohoData.agencyData.Hide_Off_Market === false && (
            <CollapsibleSection
              title='Off-Market Health Appointments'
              id='OffMarketHealthAppointments'
              subtitle='Includes STM, Indemnity, Accident, CI, Dental, Vision and Ancillary Carriers.'
              arrowRight
              expanded={expandAll}
            >
              {Object.values(carriers['Off-Market Health']).length ? (
                <MyAppointmentsSection>
                  {offMarketSortedKeys.map((key, i) => {
                    let carrier = carriers['Off-Market Health'][key];
                    return (
                      <CardNoStates
                        key={`${carrier.name} ${i}`}
                        {...carrier}
                        active={active}
                        setActive={setActive}
                      />
                    );
                  })}
                </MyAppointmentsSection>
              ) : (
                'No Off-Market Health appointments available'
              )}
            </CollapsibleSection>
          )}
          {/*zohoData.agencyData.Hide_Medicare === false && (
            <CollapsibleSection
              title='Medicare Appointments'
              id='MedicareAppointments'
              arrowRight
              expanded={expandAll}
            >
              {Object.values(carriers.Medicare).length ? (
                <MyAppointmentsSection>
                  {medicareSortedKeys.map((key, i) => {
                    let carrier = carriers.Medicare[key];
                    return (
                      <CardNoStates
                        key={`${carrier.name} ${i}`}
                        {...carrier}
                        active={active}
                        setActive={setActive}
                      />
                    );
                  })}
                </MyAppointmentsSection>
              ) : (
                'No medicare appointments available'
              )}
            </CollapsibleSection>
          )*/}
          {/*zohoData.agencyData.Hide_Life === false && (
            <CollapsibleSection
              arrowRight
              title='Life Appointments'
              id='LifeAppointments'
              expanded={expandAll}
            >
              {Object.values(carriers.Life).length ? (
                <MyAppointmentsSection>
                  {lifeSortedKeys.map((key, i) => {
                    let carrier = carriers.Life[key];
                    return (
                      <CardNoStates
                        key={`${carrier.name} ${i}`}
                        {...carrier}
                        active={active}
                        setActive={setActive}
                      />
                    );
                  })}
                </MyAppointmentsSection>
              ) : (
                'No life appointments available'
              )}
            </CollapsibleSection>
          )*/}
          {/*zohoData.agencyData.Hide_Group === false && (
            <CollapsibleSection
              arrowRight
              title='Group Appointment'
              id='GroupAppointment'
              expanded={expandAll}
            >
              {Object.values(carriers.Group).length ? (
                <MyAppointmentsSection>
                  {groupSortedKeys.map((key, i) => {
                    let carrier = carriers.Group[key];
                    return (
                      <CardNoStates
                        key={`${carrier.name} ${i}`}
                        {...carrier}
                        active={active}
                        setActive={setActive}
                      />
                    );
                  })}
                </MyAppointmentsSection>
              ) : (
                'No group appointments available'
              )}
            </CollapsibleSection>
          )*/}
        </CollapsibleList>
      ) : localLoader ? (
        <LoadingContent />
      ) : (
        <div className={style.notice}>{message}</div>
      )}
    </div>
  );
}

MainSectionCarriers.propTypes = {
  carriers: propTypes.object,
  active: propTypes.string,
  setActive: propTypes.func,
  Card: propTypes.func,
  CardNoStates: propTypes.func,
  header: propTypes.element,
  message: propTypes.string,
  localLoader: propTypes.bool
};

export default MainSectionCarriers;
