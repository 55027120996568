import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import axios from 'axios';
import Card from 'components/Card/Card';
import CollapsibleListComponent from './CollapsibleListComponent';

const AgencyManagement = ({
  localLoader,
  agenciesTree,
  getAgenciesTree,
  setLocalLoader
}) => {
  const cancelTokenSource = axios.CancelToken.source();
  useEffect(() => {
    getAgenciesTree(setLocalLoader, cancelTokenSource);
    return () => {
      cancelTokenSource.cancel('API Call Canceled');
    };
  }, []);

  const treeIsEmpty = agenciesTree.length === 1 && agenciesTree[0].length === 0;
  return (
    <Card container fill title='Agency Management' loading={localLoader}>
      <span>
        View agencies tree, agency details and update agency settings.
      </span>
      { treeIsEmpty ?  
        <>
          <br>
          </br>
          <div>You have no agency assigned.</div>
        </>
      : <CollapsibleListComponent data={agenciesTree} />
      }
    </Card>
  );
};

AgencyManagement.propTypes = {
  localLoader: propTypes.bool,
  agenciesTree: propTypes.array,
  getAgenciesTree: propTypes.func,
  setLocalLoader: propTypes.func
};

export default AgencyManagement;
