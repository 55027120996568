import Input from 'components/Input/Input';
import React from 'react';
import style from './coverageForm.module.scss';
import propTypes from 'prop-types';
import { thousandsSeparator } from 'helpers/strings';
import FormWithEditButton from '../FormWithEditButton/FormWithEditButton';

function CoverageForm({ user = {},onClick }) {
  return (
    <FormWithEditButton className={style.withButton} onClick={onClick}>
      <div className={style.coverageForm}>
        <div className={style.container}>
          <Input
            lightLabel
            disabled
            label='E & O Coverage Carrier:'
            value={user.E_O_Coverage_Carrier ? user.E_O_Coverage_Carrier : ''}
            placeholder='-'
          />
          <Input
            lightLabel
            disabled
            label='Policy number:'
            value={user.Policy_Number ? user.Policy_Number : ''}
            placeholder='-'
          />
        </div>
        <div className={style.container}>
          <Input
            lightLabel
            disabled
            label='Amount per claim:'
            value={
              user.Amount_Per_Claim
                ? `$ ${thousandsSeparator(user.Amount_Per_Claim)}`
                : ''
            }
            placeholder='-'
          />
        <Input
            lightLabel
            disabled
            label='Amount Aggregate:'
            value={
              user.Amount_Aggregate
                ? `$ ${thousandsSeparator(user.Amount_Aggregate)}`
                : ''
            }
            placeholder='-'
          />
        </div>
        <Input
          lightLabel
          disabled
          label='E & O expiration date:'
          value={user.E_O_Expiration_Date ? user.E_O_Expiration_Date : ''}
          placeholder='-'
        />
      </div>
    </FormWithEditButton>
  );
}

CoverageForm.propTypes = {
  user: propTypes.object,
  onClick: propTypes.func
};

export default CoverageForm;
