import React from 'react';
import style from './commissionCard.module.scss';
import propTypes from 'prop-types';
import { stringTrimmer } from 'helpers/strings';
import { useDispatch, useSelector } from 'react-redux';
import { getCommissions } from 'state/actions/actions';
import { ArrowForwardIos } from '@mui/icons-material';

function CommissionCard({ name, type, active, setActive = () => {} }) {
  const dispatch = useDispatch();
  const appointedCarriers = useSelector(
    state => state.appointments.appointedCarriers[type]
  );
  const appointedCarriersArray = Object.keys(appointedCarriers);

  const onCarrierClicked = carrier => {
    setActive(carrier);
    dispatch(getCommissions(carrier, type));
  };

  return (
    <div
      className={`${style.commissionCard} ${
        active === name ? style.active : null
      } ${appointedCarriersArray.includes(name) && style.appointed}`}
      onClick={() => onCarrierClicked(name)}
    >
      <span>{stringTrimmer(name, 18)}</span>
      <ArrowForwardIos fontSize='small' />
    </div>
  );
}

CommissionCard.propTypes = {
  name: propTypes.string,
  active: propTypes.string,
  type: propTypes.string,
  setActive: propTypes.func
};

export default CommissionCard;
