import React from 'react';
import style from './taskCard.module.scss';
import propTypes from 'prop-types';
import { isOverDue } from 'helpers/dateHelpers';
import { stringTrimmer } from 'helpers/strings';
import TaskHeader from './TaskHeader';

function TaskCard({
  title,
  due,
  description,
  status,
  owner,
  clickable = true,
  onClick
}) {
  const pastTask = isOverDue(due);
  return (
    <div
      className={`${style.taskCard} ${pastTask ? style.pastTask : ''} ${
        clickable ? style.clickable : ''
      }`}
      onClick={onClick}
    >
      <TaskHeader dueDate={due} title={title} status={status} owner={owner} />
      <p>{stringTrimmer(description, 260)}</p>
    </div>
  );
}

TaskCard.propTypes = {
  title: propTypes.string,
  due: propTypes.string,
  description: propTypes.string,
  status: propTypes.string,
  owner: propTypes.string,
  clickable: propTypes.bool,
  onClick: propTypes.func
};

export default TaskCard;
