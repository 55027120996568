import React from 'react';
import { CircularProgress } from '@mui/material';
import style from './LoadingContent.module.scss';

const LoadingContent = () => {
  return (
    <div className={style.loadingContent}>
      <CircularProgress size={30} />
      <span>Loading...</span>
    </div>
  );
};

export default LoadingContent;
