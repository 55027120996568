import React, { useEffect } from 'react';
import withLocalLoader from 'components/HOCs/withLocalLoader';
import { useSelector } from 'react-redux';
import propTypes from 'prop-types';

function SharedFolder({ className, setLocalLoader }) {
  const folder = useSelector(state => state.user.zohoData.sharedFolder);
  useEffect(() => {
    setLocalLoader(false);
  }, []);

  return (
    <div className={className}>
      <iframe
        src={folder}
        scrolling='no'
        frameBorder='0'
        allowFullScreen={true}
        width='100%'
      />
    </div>
  );
}

SharedFolder.propTypes = {
  className: propTypes.string,
  setLocalLoader: propTypes.func
};

export default withLocalLoader(
  SharedFolder,
  true,
  true,
  'Files',
  'Public Folder'
);
