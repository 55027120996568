import React from 'react';
import style from './commissionsTable.module.scss';
import propTypes from 'prop-types';

function CommissionsTable({ commissions }) {
  return (
    <>
      {typeof commissions === 'object' ? (
        <table className={style.commissionsTable}>
          <thead>
            <tr>
              {commissions[0].map((header, i) => (
                <th key={`${header}${i}`}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {commissions.map((commissionsArray, i) => {
              if (i > 0)
                return (
                  <tr key={i}>
                    {commissionsArray.map((commission, i) => (
                      <td key={commission + i}>
                        {commission ? commission : '---'}
                      </td>
                    ))}
                  </tr>
                );
            })}
          </tbody>
        </table>
      ) : (
        // <div className={style.commissionsTable}>
        //   <header>
        //     {commissions[0].map((header, i) => (
        //       <span
        //         key={`${header}${i}`}
        //         style={{
        //           width: i !== 0 && `${100 / (commissions[0].length - 1)}%`
        //         }}
        //       >
        //         {header}
        //       </span>
        //     ))}
        //   </header>
        //   <main>
        //     {commissions.map((commissionsArray, i) => {
        //       if (i > 0)
        //         return (
        //           <div key={i}>
        //             {commissionsArray.map((commission, i) => (
        //               <span
        //                 key={commission + i}
        //                 style={{
        //                   width:
        //                     i !== 0 && `${100 / (commissions[0].length - 1)}%`
        //                 }}
        //               >
        //                 {commission ? commission : '-'}
        //               </span>
        //             ))}
        //           </div>
        //         );
        //     })}
        //   </main>
        // </div>
        <div className={style.noInfo}>
          <span>No Information Found For This Carrier. Try again</span>
        </div>
      )}
    </>
  );
}

CommissionsTable.propTypes = {
  commissions: propTypes.array
};

export default CommissionsTable;
