import React from 'react';

function SmallArrow() {
  return (
    <svg viewBox='0 0 16.056 27.998'>
      <g transform='translate(-15.959 -10.001)'>
        <path
          d='M27.2,24,16.6,34.6a1.9,1.9,0,0,0,.2,3,2.1,2.1,0,0,0,2.7-.2l11.9-12a1.9,1.9,0,0,0,0-2.8l-11.9-12a2.1,2.1,0,0,0-2.7-.2,1.9,1.9,0,0,0-.2,3Z'
          fill='#2d83ba'
        />
      </g>
    </svg>
  );
}

export default SmallArrow;
