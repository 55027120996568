import React from 'react';
import images from 'constants/images';
import style from './hiddenSideSection.module.scss';
import propTypes from 'prop-types';

function HiddenSideSection({ text = '' }) {
  return (
    <section className={style.hiddenSideSection}>
      <img src={images.brand.fullLogoGrayScale} alt='' />
      <div>
        {text ? text : 'Select a carrier on the left to see resources details'}
      </div>
    </section>
  );
}

HiddenSideSection.propTypes = {
  text: propTypes.string
};

export default HiddenSideSection;
