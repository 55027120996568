import validator from 'helpers/validator';

function validateNewPassword(data) {
  let errors = {};
  if (!validator.isEmail(data.email)) {
    errors.email = 'Please enter a valid email address';
  }

  return { errors, isValid: validator.isEmpty(errors) };
}

export default validateNewPassword;
